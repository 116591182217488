import React from "react";
import Banner from "../commoncomponents/commonhead/BannerHead";
import TeamMember from "../components/teammember/TeamMember";

const Team = () => {
  return (
    <>
      <Banner title="Team Member" />
      <TeamMember />
    </>
  );
};

export default Team;
