import React from "react";
import Banner from "../commoncomponents/commonhead/BannerHead";
import CareerDetailcomponent from "../components/careerdetail/CareerDetailcomponent";

const CareerDetail = () => {
  return (
    <>
      <Banner title="Carrier Details" />
      <CareerDetailcomponent />
    </>
  );
};

export default CareerDetail;
