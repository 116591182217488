import React from 'react'
import { Helmet } from 'react-helmet'
import HomeBlog from '../components/home/Home_Blog'
import HomeCaseStudy from '../components/home/Home_caseStudy'
import HomeCreativeMinds from '../components/home/Home_CreativeMinds'
import HomeHead from '../components/home/Home_Head'
import HomeItService from '../components/home/Home_ItService'
import HomeItSolution from '../components/home/Home_ItSolution'
import HomeWhyChoose from '../components/home/Home_whyChoose'

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Shine Infosoft</title>
        <meta
          name="Shine Infosoft"
          content="It Service Provide for Different Technology"
        />
        <meta
          name="IT Service Company"
          content="React, Angular, NodeJs, .net, React Native"
        />
      </Helmet>
      <HomeHead/>
      <HomeCreativeMinds/>
      <HomeItService/>
      <HomeItSolution/>
      <HomeWhyChoose/>
      <HomeCaseStudy/>
      <HomeBlog/>
    </>
  )
}

export default Home
