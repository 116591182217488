import React from "react";
import about_team1 from "../../assets/about/about_team1.jpg";
import about_team2 from "../../assets/about/about_team2.jpg";
import about_team3 from "../../assets/about/about_team3.jpg";
import about_team4 from "../../assets/about/about_team4.jpg";
import FurtherInformation from "../../commoncomponents/commmonBodyPart/furtherInformation";

const BlogTeamMember = () => {
  const teamMember = [
    {
      img: about_team1,
      member_name: "David Malaan",
      position: "Director",
    },
    {
      img: about_team2,
      member_name: "Andres Jhohne",
      position: "CEO",
    },
    {
      img: about_team3,
      member_name: "Michel Balak",
      position: "FOUNDER",
    },
    {
      img: about_team4,
      member_name: "Jemes Rodrigez",
      position: "MANAGER",
    },
  ];
  var a = 0;
  window.scroll(function () {
    var oTop =
      document.getElementById("#counter").offset().top - window.innerHeight;
    if (a == 0 && window.scrollTo() > oTop) {
      document.getElementsByclassName(".counter").each(function () {
        var check = this,
          countTo = check.attr("data-count");
        ({
          countNum: check.text(),
        }.animate(
          {
            countNum: countTo,
          },

          {
            duration: 2000,
            easing: "swing",
            step: function () {
              check.text(Math.floor(this.countNum));
            },
            complete: function () {
              check.text(this.countNum);
              //alert('finished');
            },
          }
        ));
      });
      a = 1;
    }
  });
  return (
    <section className="teamMemberSection">
      <div className="container pb-80">
        <div className="row">
          <div className="col-lg-9 mb-60">
            <h6 className="mb-3">TEAM MEMBER</h6>
            <h1>
              <b>Our Awesome Creative</b>
            </h1>
            <h1>
              <b>Team Member</b>
            </h1>
            <div className="decorative_bar">
              <div className="decorative_bar_bg"></div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="team_btn mt-50 text-right">
              <a href="#">Join Our Team</a>
            </div>
          </div>
        </div>
        <div className="row">
          {teamMember.map((member) => {
            return (
              <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                <div className="teamMember mb-40">
                  <div className="teamMember_inner">
                    <div className="teamMember_thumb">
                      <div className="teamMember_thumb_inner">
                        <img src={member.img} alt="" />
                      </div>
                    </div>
                    <div className="teamMember_content">
                      <div className="teamMember_title">
                        <h4>{member.member_name}</h4>
                      </div>
                      <div className="teamMember_sub_title">
                        <span>{member.position}</span>
                      </div>
                      <div className="teamMember_icon">
                        <a href="#">
                          <i className="fa-brands fa-facebook"></i>
                        </a>
                        <a href="#">
                          <i className="fa-brands fa-twitter"></i>
                        </a>
                        <a href="#">
                          <i className="fa-brands fa-linkedin"></i>
                        </a>
                        <a href="#">
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <FurtherInformation />
      <div className="counter_section" id="counter_section">
        <div className="container">
          <div className="row counter_bg_up counter_down_to_up pt-50 pb-45">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="single_counter text-center mb-4">
                <div className="counter_text">
                  <h1>
                    <span className="counter">15</span>
                    <span>K</span>
                  </h1>
                </div>
                <div className="counter_desc">
                  <h5>Happy Clients</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="single_counter text-center mb-4">
                <div className="counter_text">
                  <h1>
                    <span className="counter">1280</span>
                    <span>+</span>
                  </h1>
                </div>
                <div className="counter_desc">
                  <h5>Account Number</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="single_counter text-center mb-4">
                <div className="counter_text">
                  <h1>
                    <span className="counter">10</span>
                    <span>K</span>
                  </h1>
                </div>
                <div className="counter_desc">
                  <h5>Finished Projects</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="single_counter text-center mb-4">
                <div className="counter_text">
                  <h1>
                    {" "}
                    <span className="counter">992</span>
                    <span>+</span>
                  </h1>
                </div>
                <div className="counter_desc">
                  <h5>Win Awards</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogTeamMember;
