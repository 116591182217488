import React, { useEffect, useState } from "react";
import logo_full from "../../assets/header/logo_full.png";
import pirotan_logo_white from "../../assets/header/pirotan_logo_white.png";
import PIROTAN_LOGO_1 from "../../assets/header/PIROTAN_LOGO-1.jpg";
import { Link } from "react-router-dom";

const Header = () => {
  const [navbar, setNavbar] = useState(false);
  const [navShow, setNavShow] = useState(true);
  const [navHomeShow, setNavHomeShow] = useState(false);
  const [navCompnyMenuShow, setNavCompnyMenuShow] = useState(false);
  const [navServiceMenuShow, setNavServiceMenuShow] = useState(false);

  //logo scroll when active
  const [navbarLogo, setNavbarLogo] = useState(logo_full);

  //navbar scroll changeBackground function
  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  });

  //logo scroll function
  const changeLogo = () => {
    if (window.scrollY > 70) {
      setNavbarLogo(pirotan_logo_white);
    } else {
      setNavbarLogo(logo_full);
    }
  };

  useEffect(() => {
    changeLogo();
    // adding the event when scroll change Logo
    window.addEventListener("scroll", changeLogo);
  });

  const changeToggle = () => {
    setNavShow(false);
  };
  const closeToggle = () => {
    setNavShow(true);
    setNavCompnyMenuShow(false);
    setNavServiceMenuShow(false);
  };
  const homeMenuShow = () => {
    setNavHomeShow(true);
  };
  const homeMenuClose = () => {
    setNavHomeShow(false);
  };
  const companyMenuShow = () => {
    setNavCompnyMenuShow(true);
  };
  const companyMenuClose = () => {
    setNavCompnyMenuShow(false);
  };
  const serviceMenuShow = () => {
    setNavServiceMenuShow(true);
  };
  const serviceMenuClose = () => {
    setNavServiceMenuShow(false);
  };
  return (
    <>
      <section
        id="sticky-header"
        className={
          navbar
            ? "header_section d-md-none d-lg-block d-sm-none d-none sticky"
            : "header_section d-md-none d-lg-block d-sm-none d-none"
        }
      >
        <div className="container">
          <div className="row">
            <div className="header_menu">
              <Link onClick={closeToggle} to="/" className="logo">
                <img className="down" src={pirotan_logo_white} alt="" />
                <img className="main_sticky" src={PIROTAN_LOGO_1} alt="" />
              </Link>
              <Link to="#" className="menu-mobile">
                &nbsp;
              </Link>

              <ul className="clearfix">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="menu-dropdown-icon">
                  <Link to="#">Company</Link>
                  <ul className="normal-sub">
                    <li>
                    <Link to="/about"><i class="fa-regular fa-address-card"></i> About Us</Link>
                    </li>
                    <li>
                      <Link to="/career"><i class="fa-solid fa-user-tie"></i> Career</Link>
                    </li>
                    <li>
                      <Link to="/career_detail"><i class="fa-solid fa-user-tie"></i> Career Detail</Link>
                    </li>
                    <li>
                      <Link to="/portfolio"><i class="fa-solid fa-network-wired"></i> Portfolio</Link>
                    </li>
                    <li>
                      <Link to="/team_member"><i class="fa-solid fa-people-group"></i> Team Details</Link>
                    </li>
                  </ul>
                </li>
                <li className="menu-dropdown-icon">
                  <Link to="#">Service</Link>
                  <ul className="normal-sub">
                    <li>
                      <Link to="/web_service"><i class="fa-solid fa-laptop-code"></i> Web Application Development</Link>
                    </li>
                    <li>
                      <Link to="/mob_service"><i class="fa-solid fa-mobile-screen-button"></i> Mobile App Development</Link>
                    </li>
                    <li>
                      <Link to="/front_service"><i class="fa-solid fa-code"></i> Front End Development</Link>
                    </li>
                    <li>
                      <Link to="/back_service"><i class="fa-brands fa-node"></i> Back End Development</Link>
                    </li>
                  </ul>
                </li>
                <li className="menu-dropdown-icon">
                  <Link to="#">It Solution</Link>
                </li>
                <li className="menu-dropdown-icon">
                  <Link to="#">Element</Link>
                </li>
                <li className="menu-dropdown-icon">
                  <Link to="#">Blog</Link>
                  <ul className="normal-sub">
                    <li>
                      <Link to="/blog"><i class="fa-solid fa-people-arrows"></i> Blog</Link>
                    </li>
                    <li>
                      <Link to="/blogdetail"><i class="fa-solid fa-people-arrows"></i> Blog Details</Link>
                    </li>
                  </ul>
                </li>
                <li className="menu-dropdown-icon">
                  <Link to="/contact">Contact</Link>
                </li>
                <div className="donate-btn-header">
                  <Link className="dtbtn" href="#">
                    Get A Quote
                  </Link>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="mobile-menu-area d-sm-block d-md-block d-lg-none">
        <div className="mobile-menu mean-container">
          <div className="mean-bar">
            {navShow ? (
              <Link
                href="#nav"
                className="meanmenu-reveal"
                // style="background:;color:;right:0;left:auto;"
                onClick={changeToggle}
              >
                <span></span>
                <span></span>
                <span></span>
              </Link>
            ) : (
              <>
                <Link
                  href="#"
                  className="meanmenu-reveal"
                  onClick={closeToggle}
                >
                  X
                </Link>

                <nav className="mean-nav">
                  <ul className={`${navShow ? "d-none" : "myclassName"}`}>
                    <li>
                      <Link onClick={closeToggle} to="/">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="#">Company</Link>
                      <ul
                        className={`${
                          navCompnyMenuShow ? "myclassName_2" : "d-none"
                        }`}
                      >
                        <li>
                          <Link onClick={closeToggle} to="/about">
                            About Us
                          </Link>
                        </li>
                        <li>
                          <Link onClick={closeToggle} to="/career">
                            Career
                          </Link>
                        </li>
                        <li>
                          <Link onClick={closeToggle} to="/career_detail">
                            Career Detail
                          </Link>
                        </li>
                        <li>
                          <Link onClick={closeToggle} to="/portfolio">
                            Portfolio
                          </Link>
                        </li>
                        <li>
                          <Link onClick={closeToggle} to="/team_member">
                            Team Details
                          </Link>
                        </li>
                      </ul>
                      {navCompnyMenuShow ? (
                        <Link
                          className="mean-expand"
                          to="#"
                          style={{ fontSize: "18px" }}
                          onClick={companyMenuClose}
                        >
                          -
                        </Link>
                      ) : (
                        <Link
                          className="mean-expand"
                          to="#"
                          style={{ fontSize: "18px" }}
                          onClick={companyMenuShow}
                        >
                          +
                        </Link>
                      )}
                    </li>
                    <li>
                      <Link to="#">Service</Link>
                      <ul
                        className={`${
                          navServiceMenuShow ? "myclassName_2" : "d-none"
                        }`}
                      >
                        <li>
                          <Link onClick={closeToggle} to="/web_service">
                            Web Application Development
                          </Link>
                        </li>
                        <li>
                          <Link onClick={closeToggle} to="/mob_service">
                            Mobile App Development
                          </Link>
                        </li>
                        <li>
                          <Link onClick={closeToggle} to="/front_service">
                            Front End Development
                          </Link>
                        </li>
                        <li>
                          <Link onClick={closeToggle} to="/back_service">
                            Back End Development
                          </Link>
                        </li>
                      </ul>
                      {navServiceMenuShow ? (
                        <Link
                          className="mean-expand"
                          to="#"
                          style={{ fontSize: "18px" }}
                          onClick={serviceMenuClose}
                        >
                          -
                        </Link>
                      ) : (
                        <Link
                          className="mean-expand"
                          to="#"
                          style={{ fontSize: "18px" }}
                          onClick={serviceMenuShow}
                        >
                          +
                        </Link>
                      )}
                    </li>
                    <li>
                      <Link to="#">It Solution</Link>
                    </li>
                    <li>
                      <Link to="#">Element</Link>
                    </li>
                    <li>
                      <Link to="#">Blog</Link>
                      <ul className="normal-sub">
                        <li>
                          <Link onClick={closeToggle} to="/blog">
                            Blog
                          </Link>
                        </li>
                        <li>
                          <Link onClick={closeToggle} to="/blogdetail">
                            Blog Details
                          </Link>
                        </li>
                      </ul>
                      <Link
                        className="mean-expand"
                        href="#"
                        style={{ fontSize: "18px" }}
                      >
                        +
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </nav>
              </>
            )}
          </div>
          <div className="mean-push"></div>
        </div>
      </div>
    </>
  );
};

export default Header;
