import React from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import team_pages from "../../assets/teammember/team_pages.png";
import AnimatedProgressProvider from "./AnimatedProgressProvider";
import team1 from "../../assets/teammember/team1.jpg";
import team2 from "../../assets/teammember/team2.jpg";
import team3 from "../../assets/teammember/team3.jpg";
import team4 from "../../assets/teammember/team4.jpg";

const TeamMember = () => {
  return (
    <>
      <section className="teamMember_section pt-85 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-6">
              <div className="section_title text_left mb-40 mt-3">
                <div className="section_sub_title uppercase mb-3">
                  <h6>EXPERTISE OF TEAM</h6>
                </div>
                <div className="section_main_title">
                  <h1> Our Experienced Team</h1>
                  <h1>for your Business!!</h1>
                </div>
                <div className="team_member_text pt-3">
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available but the majority have suffered alteration in that
                    injected
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="business_marketing">
                    <AnimatedProgressProvider valueStart={0} valueEnd={70}>
                      {(value) => (
                        <CircularProgressbarWithChildren
                          className="CircularProgressbar"
                          value={value}
                          text={`${value}%`}
                          styles={buildStyles({
                            pathColor: "#5451C1",
                            trailColor: "#eee",
                            strokeLinecap: "butt",
                            pathTransitionDuration: "3",
                          })}
                        />
                      )}
                    </AnimatedProgressProvider>
                    <h2>Design</h2>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="business_marketing">
                    <AnimatedProgressProvider valueStart={0} valueEnd={90}>
                      {(value) => (
                        <CircularProgressbarWithChildren
                          value={value}
                          text={`${value}%`}
                          styles={buildStyles({
                            pathColor: "#25C4F8",
                            trailColor: "#eee",
                            strokeLinecap: "butt",
                            pathTransitionDuration: "3",
                          })}
                        />
                      )}
                    </AnimatedProgressProvider>
                    <h2>Development</h2>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="business_marketing">
                    <AnimatedProgressProvider valueStart={0} valueEnd={90}>
                      {(value) => (
                        <CircularProgressbarWithChildren
                          value={value}
                          text={`${value}%`}
                          styles={buildStyles({
                            pathColor: "#E6513A",
                            trailColor: "#eee",
                            strokeLinecap: "butt",
                            pathTransitionDuration: "3",
                          })}
                        />
                      )}
                    </AnimatedProgressProvider>
                    <h2>Marketing</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-6">
              <div className="team_join_thumb">
                <img src={team_pages} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="container pb-80">
          <div className="row">
            <div className="col-lg-9 mb-60">
              <h6 className="mb-3">TEAM MEMBER</h6>
              <h1>
                <b>Our Awesome Creative</b>
              </h1>
              <h1>
                <b>Team Member</b>
              </h1>
              <div className="decorative_bar">
                <div className="decorative_bar_bg"></div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="team_btn mt-50 text-right">
                <a href="#">Join Our Team</a>
              </div>
            </div>
          </div>
          <div className="row">
            {teamMember.map((team, i) => {
              return (
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="single_team mb-30">
                    <div className="single_team_thumb">
                      <img src={team.img} alt="" />
                      <div className="single_team_icon">
                        {team.href.map((icon, i) => {
                          return <a href="#">{icon.img}</a>;
                        })}
                      </div>
                    </div>
                    <div className="single_team_content">
                      <h4>{team.author}</h4>
                      <span>{team.auhtor_post}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};
const teamMember = [
  {
    img: team1,
    href: [
      {
        img: <i className="fa-brands fa-facebook-f"></i>,
      },
      {
        img: <i className="fa-brands fa-twitter"></i>,
      },
      {
        img: <i className="fa-brands fa-linkedin-in"></i>,
      },
      {
        img: <i className="fa-brands fa-pinterest"></i>,
      },
    ],
    author: "David Malaan",
    auhtor_post: "CEO",
  },
  {
    img: team2,
    href: [
      {
        img: <i className="fa-brands fa-facebook-f"></i>,
      },
      {
        img: <i className="fa-brands fa-twitter"></i>,
      },
      {
        img: <i className="fa-brands fa-linkedin-in"></i>,
      },
      {
        img: <i className="fa-brands fa-pinterest"></i>,
      },
    ],
    author: "Andres Jhohne",
    auhtor_post: "DIRECTOR",
  },
  {
    img: team3,
    href: [
      {
        img: <i className="fa-brands fa-facebook-f"></i>,
      },
      {
        img: <i className="fa-brands fa-twitter"></i>,
      },
      {
        img: <i className="fa-brands fa-linkedin-in"></i>,
      },
      {
        img: <i className="fa-brands fa-pinterest"></i>,
      },
    ],
    author: "Michel Balak",
    auhtor_post: "FOUNDER",
  },
  {
    img: team4,
    href: [
      {
        img: <i className="fa-brands fa-facebook-f"></i>,
      },
      {
        img: <i className="fa-brands fa-twitter"></i>,
      },
      {
        img: <i className="fa-brands fa-linkedin-in"></i>,
      },
      {
        img: <i className="fa-brands fa-pinterest"></i>,
      },
    ],
    author: "Jemes Rodrigez",
    auhtor_post: "MANAGER",
  },
  {
    img: team1,
    href: [
      {
        img: <i className="fa-brands fa-facebook-f"></i>,
      },
      {
        img: <i className="fa-brands fa-twitter"></i>,
      },
      {
        img: <i className="fa-brands fa-linkedin-in"></i>,
      },
      {
        img: <i className="fa-brands fa-pinterest"></i>,
      },
    ],
    author: "Hossain",
    auhtor_post: "Admin",
  },
  {
    img: team2,
    href: [
      {
        img: <i className="fa-brands fa-facebook-f"></i>,
      },
      {
        img: <i className="fa-brands fa-twitter"></i>,
      },
      {
        img: <i className="fa-brands fa-linkedin-in"></i>,
      },
      {
        img: <i className="fa-brands fa-pinterest"></i>,
      },
    ],
    author: "Jobayer Khan",
    auhtor_post: "CEO Founder",
  },
];
export default TeamMember;
