import React from "react";
import Banner from "../commoncomponents/commonhead/BannerHead";
import BlogItSolution from "../components/about/About_ItSolution";
import BlogTeamMember from "../components/about/About_TeamMember";
import AboutTestimonial from "../components/about/About_Testimonial";

const About = () => {
  return (
    <>
      <Banner title="About Us"/>
      <BlogItSolution />
      <BlogTeamMember />
      <AboutTestimonial />
    </>
  );
};

export default About;
