import React from "react";
import { Link } from "react-router-dom";
import about from "../../assets/home/home_itService/about.png";
import icon from "../../assets/home/home_itService/icon.png";
import icon1 from "../../assets/home/home_itService/icon1.png";
import home_figma from "../../assets/home/home_itService/home_figma.png";
import home_google from "../../assets/home/home_itService/home_google.png";
import home_sket from "../../assets/home/home_itService/home_sket.png";
import home_redit from "../../assets/home/home_itService/home_redit.png";

const Home_ItService = () => {
  const socialImage = [
    {
      src: home_figma,
    },
    {
      src: home_google,
    },
    {
      src: home_sket,
    },
    {
      src: home_redit,
    },
  ];
  return (
    <>
      <section className="HomeItService_section pt-120 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-12 col-md-12">
              <div className="home_about_1">
                <img src={about} alt="" />
              </div>
            </div>

            <div className="col-lg-6 col-sm-12 col-md-12 center">
              <div className="section_sub_title mb-3">
                <h5>About Techno</h5>
              </div>
              <div className="section_main_title">
                <h1 className="mt-3 mb-3">
                  <b>
                    Choose The Best IT Service <span>Company</span>
                  </b>
                </h1>
              </div>
              <div className="section_content_text">
                <p>
                  Completely strategize client-centric niche markets for go
                  forward and the Phosfluorescently iterate efficient internal
                  or "organic" sources wh theres revolutionary services.
                  Objectively crosplatform data scalable deliverables.
                  Holisticly enhance and it solutions.
                </p>
              </div>
              <div className="service_help">
                <div className="row">
                  <div className="col-lg-6 col-sm-6 col-md-9">
                    <div className="em-about-icon">
                      <img className="m-3" src={icon} alt="" />{" "}
                    </div>
                    <div className="em-about-title">
                      <h3 className="mt-3">Moneyback Gurentee</h3>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-md-9">
                    <div className="em-about-icon">
                      <img className="m-3" src={icon1} alt="" />{" "}
                    </div>
                    <div className="em-about-title">
                      <h3 className="mt-3">Technical Support</h3>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-5 col-sm-6 col-md-6">
                    <div className="mt-40">
                      <div className="abou-button1">
                        <Link className="linktag" to="/">
                          Contact Us &#x2192;
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 col-sm-7 col-md-6">
                    <div className="mt-40">
                      <div className="em-about-icons">
                        <i className="fa-solid fa-phone-volume"></i>
                      </div>
                    </div>
                    <div className="em-about-title2">
                      <p>Call for Help</p>
                      <h3>+98 321 (7690) 326</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-80">
            <div className="col-lg-4 socialImage">
              <h6>
                <b>Join over +18,000 happy Clients</b>
              </h6>
            </div>
            {socialImage.map((item) => {
              return (
                <div className="col-lg-2 socialImage">
                  <img src={item.src} alt="" />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Home_ItService;
