import React from "react";
import { Link } from "react-router-dom";
import img1 from "../../assets/portfolio/p1.jpg";
import img2 from "../../assets/portfolio/p2.jpg";
import img3 from "../../assets/portfolio/p3.jpg";
import img4 from "../../assets/portfolio/p4.jpg";
import img5 from "../../assets/portfolio/p5.jpg";
import img6 from "../../assets/portfolio/p6.jpg";

const BusinessImages = () => {
  const businessmodaldeta = [
    {
      img: img1,
      content: "Prototype UX Research",
      Heading: "Digital Marketing",
    },
    { img: img2, content: "UX Research", Heading: "Awesome creative" },
    { img: img3, content: "Design, PhotoShop", Heading: "Business Solution" },
    { img: img4, content: "Software Engineer", Heading: "Company Project" },
    { img: img5, content: "PhotoShop", Heading: "Mastering Web Design" },
    { img: img6, content: "Design, PhotoShop", Heading: "Awesome Logo Design" },
  ];
  return (
    <>
      <section className="businessimg_section">
        <div className="container">
          <div className="row businessimg_row">
            {businessmodaldeta &&
              businessmodaldeta.map((el) => {
                return (
                  <div className="col-lg-4 col-md-6 col-sm-12 d-grid physics english ">
                    <div className="card_portfolio">
                      <div className="portfolio_img">
                        <img src={el.img} alt="" />
                      </div>
                      <ul className="sci">
                        <div className="picon mt-3 mb-3">
                          <a className="falt_icon" href="#">
                            <i className="fa-solid fa-image"></i>
                          </a>
                          <a className="falt_icon" href="#">
                            <i className="fa-solid fa-link"></i>
                          </a>
                        </div>
                        <li className="port_fs20">
                          <a href="#">Awesome Logo Design</a>
                        </li>
                        <li className="port_fs15">UX Research</li>
                      </ul>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </>
  );
};

export default BusinessImages;
