import React from "react";
import PageTitle from "../../commoncomponents/commonPageTitle/pageTitle";
import "../../scss/career/_careerhiring.scss";

const CareerHiring = () => {
  return (
    <>
      <section className="careerhiring_section">
        <div className="container my-5">
          <PageTitle h6_title="JOB CIRCULAR" h1_title="We Are Hiring" />
          <div className="row my-5">
            <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
              <div className="card hiring_card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12 col-md-6 col-sm-12 mb-1">
                      <h3 className="card_title">Web Developer</h3>
                    </div>
                    <div className="col-lg-12 col-md-6 col-sm-12">
                      <h5 className="card_title_h5 mb-2">Full Time</h5>
                      <p className="card-text p-2">
                        There are many variations of passages of Lorem Ipsum
                        available, but the majority have suffered alteration in
                        some form, by injected humour, or randomised words which
                      </p>
                      <a
                        href="#"
                        className="btn btn-primary mx-2 my-3 hiring_btn"
                      >
                        Job Details
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
              <div className="card hiring_card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12 col-md-6 col-sm-12 mb-1">
                      <h3 className="card_title">Front End developer</h3>
                    </div>
                    <div className="col-lg-12 col-md-6 col-sm-12">
                      <h5 className="card_title_h5 mb-2">Full Time</h5>
                      <p className="card-text p-2">
                        There are many variations of passages of Lorem Ipsum
                        available, but the majority have suffered alteration in
                        some form, by injected humour, or randomised words which
                      </p>
                      <a
                        href="#"
                        className="btn btn-primary mx-2 my-3 hiring_btn"
                      >
                        Job Details
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
              <div className="card hiring_card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12 col-md-6 col-sm-12 mb-1">
                      <h3 className="card_title">UI/UX Designer</h3>
                    </div>

                    <div className="col-lg-12 col-md-6 col-sm-12">
                      <h5 className="card_title_h5 mb-2">Full Time</h5>
                      <p className="card-text p-2">
                        There are many variations of passages of Lorem Ipsum
                        available, but the majority have suffered alteration in
                        some form, by injected humour, or randomised words which
                      </p>
                      <a
                        href="#"
                        className="btn btn-primary mx-2 my-3 hiring_btn"
                      >
                        Job Details
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
              <div className="card hiring_card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12 col-md-6 col-sm-12 mb-1">
                      <h3 className="card_title">Computer Operator</h3>
                    </div>

                    <div className="col-lg-12 col-md-6 col-sm-12">
                      <h5 className="card_title_h5 mb-2">Full Time</h5>
                      <p className="card-text p-2">
                        There are many variations of passages of Lorem Ipsum
                        available, but the majority have suffered alteration in
                        some form, by injected humour, or randomised words which
                      </p>
                      <a
                        href="#"
                        className="btn btn-primary mx-2 my-3 hiring_btn"
                      >
                        Job Details
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CareerHiring;
