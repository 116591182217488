import React from "react";

const PageTitle = ({ h6_title, h1_title, h1_second_title, span_text }) => {
  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-md-10 col-sm-12">
          <div className="commonPageTitle text-center mb-55">
            <div className="ExclusiveServices_sub_title uppercase mb-3">
              <h6>{h6_title}</h6>
            </div>
            <div className="ExclusiveServices_main_title text-center">
              <h1>{h1_title} </h1>
            </div>
            <div className="ExclusiveServices_main_title text-center">
              <h1>
                {h1_second_title} <span>{span_text}</span>
              </h1>
            </div>
            <div className="ExclusiveServices_em_bar">
              <div className="ExclusiveServices_em_bar_bg"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageTitle;
