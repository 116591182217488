import React from "react";
import home_about_2 from "../../assets/about/home_about_2.jpg";

const BlogItSolution = () => {
  const facility = [
    {
      icon: <i className="fa-solid fa-computer"></i>,
      title: "Responsive Design",
      bck_title: "Responsive Design",
      desc: " Our experience design arm, method, helps businesses connect the dots",
    },
    {
      icon: <i className="fa-solid fa-computer"></i>,
      title: "24/7 Online Support",
      bck_title: "24/7 Online Support",
      desc: "Our experience design arm, method, helps businesses connect the dots",
    },
    {
      icon: <i className="fa-solid fa-computer"></i>,
      title: "Quality Product",
      bck_title: "Quality Product",
      desc: "Our experience design arm, method, helps businesses connect the dots",
    },
    {
      icon: <i className="fa-solid fa-computer"></i>,
      title: "Productivi Software",
      bck_title: "Productivi Software",
      desc: "Our experience design arm, method, helps businesses connect the dots",
    },
  ];
  return (
    <section className="solution_section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="home_about_2">
              <img src={home_about_2} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="text_left">
              <div className="uppercase">
                <h6>30 YEARS OF EXPERIENCE</h6>
              </div>
              <h1>
                Preparing For Your Success Provide Best{" "}
                <span className="blue">IT Solutions.</span>
              </h1>
              <div className="decorative_bar">
                <div className="decorative_bar_bg"></div>
              </div>
            </div>
            <div className="solution_p pt-5">
              <p>
                Voice and Data Systems are crucial to the success or failure of
                most businesses. any companies provide laptops, cell phones.
              </p>
            </div>
            <div className="solution_desc">
              <div className="solution_desc_border">
                <p>
                  The standard chunk of Lorem Ipsum used since the 1500s is and
                  reproduced below for those interested. Sections 1.10.32 and
                  also 1.10.33 from “de Finibus Bonorum et Malorum" by Cicero
                  are alse reproduced in their exact original form, accompanied
                </p>
                <p>
                  The standard chunk of Lorem Ipsum used since the 1500s is and
                  reproduced below for those interested
                </p>
              </div>
            </div>
            <div className="solution_desc_botton">
              <div className="solution_desc_botton_2">
                <a href="#">More Details</a>
              </div>
            </div>
          </div>
        </div>
        <div className="row pb-70">
          {facility.map((menu) => {
            return (
              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="supportive mb-30">
                  <div className="supportive_font">
                    <div className="supportive_font_inner">
                      <div className="supportive_font_inner_icon">
                        <div className="icon">{menu.icon}</div>
                      </div>
                      <div className="supportive_title">
                        <h3>{menu.title}</h3>
                      </div>
                    </div>
                  </div>
                  <div className="supportive_back">
                    <div className="supportive_font_inner">
                      <div className="supportive_title">
                        <h3>{menu.bck_title}</h3>
                        <p>{menu.desc}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default BlogItSolution;
