import React from "react";

const FurtherInformation = () => {
  return (
    <>
      <div className="mt-90 furtherInformation_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="furtherInformation_title text-center mb-60 mt-3">
                <div className="mob_Number mb-3">
                  <h5>+880 013 143 206</h5>
                </div>
                <div className="furtherInformation_main_title">
                  <h1>To make requests for the</h1>
                  <h1>further information</h1>
                </div>
                <div className="button three mt-40">
                  <a href="#">
                    Join With Now <i className="fa fa-long-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FurtherInformation;
