import React from "react";
import ReactOwlCarousel from "react-owl-carousel";

import b1 from "../../assets/commonbodybotttomcrousal/b1.png";
import b2 from "../../assets/commonbodybotttomcrousal/b2.png";
import b3 from "../../assets/commonbodybotttomcrousal/b3.png";
import b4 from "../../assets/commonbodybotttomcrousal/b4.png";
import b5 from "../../assets/commonbodybotttomcrousal/b5.png";
import b6 from "../../assets/commonbodybotttomcrousal/b6.png";

const BodyBottomCrousal = () => {
  const crousalImage = [
    {
      b1: b2,
    },
    {
      b1: b1,
    },
    {
      b1: b3,
    },
    {
      b1: b4,
    },
    {
      b1: b5,
    },
    {
      b1: b6,
    },
  ];
  return (
    <>
      <section className="bodybottomcrousal_section pt-35 pb-15">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="row">
                <div className="brand_list owl-carousel curosel-style owl-loaded owl-drag">
                  <ReactOwlCarousel
                    className="owl-theme mb-30"
                    items={6}
                    loop
                    margin={10}
                  >
                    {crousalImage.map((img, i) => {
                      return (
                        <div key={i} className="item cloned pb-5">
                          <div className="col-lg-12">
                            <div className="single_brand mt-3">
                              <div className="single_brand_thumb">
                                <img
                                  className="mt-5 pt-8"
                                  src={img.b1}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </ReactOwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BodyBottomCrousal;
