import React from 'react'
import Banner from '../commoncomponents/commonhead/BannerHead'
import ContactDetail from '../components/contact/ContactDetail'
import ContactForm from '../components/contact/ContactForm'
import ContactMap from '../components/contact/ContactMap'

const Contact = () => {
  return (
    <>
      <Banner title={"Contact Page"} />
      <ContactDetail />
      <ContactMap />
      <ContactForm />
    </>
  )
}

export default Contact