import React from "react";
import { Link } from "react-router-dom";

const Banner = ({ title }) => {
  return (
    <>
      <section className="ServiceBannerSection d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ServiceBanner_title">
                <div className="ServiceBanner_title_inner pb-2">
                  <h2>{title}</h2>
                </div>
                <div className="ServiceBanner_content">
                  <ul>
                    <li>
                      <Link to="">Home</Link>
                      <i className="fa fa-angle-right"></i>
                      <Link to=""> Pages</Link>{" "}
                      <i className="fa fa-angle-right"></i>
                      <span>{title}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
