import React from "react";
import brand_1 from "../../assets/about/brand_1.png";

const About_Testimonial = () => {
  return (
    <>
      <section className="testimonial_section pt-80 pb-70">
        <div className="row">
          <div className="col-lg-12">
            <div className="testimonial_title_section text-center mt-3">
              <div className="testimonial_title_section_sub_title mb-3">
                <h6>TESTIMONIAL</h6>
              </div>
              <div className="testimonial_title_section_main_title">
                <h1>
                  Our Happy <span>Clients Says</span>
                </h1>
              </div>
              <div className="em_bar">
                <div className="em_bar_bg"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-pe-quote left">
          <div className="pp-quote li-quote-1" data-textquote="quote-text-1">
            <div className="img animated bounce"></div>
          </div>
        </div>
        <div className="container-quote">
          <div
            className="quote quote-text-3 hide-bottom show"
            data-ppquote="li-quote-3"
          >
            <p>
              'Managing everyday banking has finally become fun, fast and easy.
              Icing on the cake, customer support is super reactive: 5 stars!'
            </p>
            <div className="container-info">
              <div className="pp"></div>
              <div className="name">Tom Abel De</div>
              <div className="job">Digital Marketing</div>
            </div>
          </div>
        </div>
        <div className="container-pe-quote right">
          <div className="pp-quote li-quote-7" data-textquote="quote-text-1">
            <div className="img animated bounce"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About_Testimonial;
