import React from "react";

const Home_CreativeMinds = () => {
  return (
    <>
      <section className="feature_area pt-85">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section_title text-center mb-60 mt-3">
                <div className="section_sub_title upper mb-20">
                  <h5>Featured Services</h5>
                </div>
                <div className="section_main_title upper pb-15">
                  <h1>
                    Engaging Creative <span> Minds </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="em-feature-single-box wow">
                <div className="feature-single-box-inner">
                  <div className="em-feature-title">
                    <h2>Team Brainstroming</h2>
                    <p>
                      Globally develop the unique technologies <br /> Seamlessly
                      cultivate extensible
                    </p>
                  </div>
                  <div className="em-feature-button">
                    <a href="#">Learn More</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                className="em-feature-single-box wow fadeInRight animated"
                data-wow-delay=".4"
              >
                <div className="feature-single-box-inner1">
                  <div className="em-feature-title">
                    <h2>Business Collaboration</h2>
                    <p>
                      Globally develop the unique technologies <br /> Seamlessly
                      cultivate extensible
                    </p>
                  </div>
                  <div className="em-feature-button">
                    <a href="#">Learn More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home_CreativeMinds;
