import React, { useEffect } from "react";
import Banner from "../../../commoncomponents/commonhead/BannerHead";
import shape2 from "../../../assets/services/mobappdevelopment/shape2.png";
import About_Testimonial from "../../about/About_Testimonial";
import PageTitle from "../../../commoncomponents/commonPageTitle/pageTitle";
import CommonResource from "../../../commoncomponents/commonResource/CommonResource";

const MobileAppDev = () => {
  useEffect(() => {
    window.scrollTo({ top: "0" });
  }, []);

  return (
    <>
      <Banner title="Industries" />
      <section className="MobileServicesSection pt-85 pb-10">
        <div className="container">
          <PageTitle
            h6_title="IT SERVICES"
            h1_title="Preparing For Your Success, We Provide Truly"
            span_text="Prominent IT Solutions."
          />
          <div className="row">
            {mob_app_service.map((service, i) => {
              return (
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="service_style_seven pt-3 pb-3 pl-4 pr-3 mb-30">
                    <div className="service_style_seven_icon mr-3">
                      {service.icon}
                    </div>
                    <div className="service_style_seven_content ">
                      <div className="service_style_seven_title pb-3">
                        <h4>{service.title}</h4>
                      </div>
                      <div className="service_style_seven_text">
                        <p>{service.desc}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <CommonResource />
        <About_Testimonial />
      </section>
    </>
  );
};
const mob_app_service = [
  {
    icon: <i className="fa fa-desktop"></i>,
    title: "IT Design",
    desc: " There are many variations of passages of Lorem Ipsum an available, but the majority have suffered alteration in thes some form, by injected humour, or randomised",
  },
  {
    icon: <i className="fa-regular fa-share-from-square"></i>,
    title: "IT Management",
    desc: " There are many variations of passages of Lorem Ipsum an available, but the majority have suffered alteration in thes some form, by injected humour, or randomised",
  },
  {
    icon: <i className="fa-regular fa-clock"></i>,
    title: "Data Security",
    desc: " There are many variations of passages of Lorem Ipsum an available, but the majority have suffered alteration in thes some form, by injected humour, or randomised",
  },
  {
    icon: <i className="fa-solid fa-circle-notch"></i>,
    title: "Business Reform",
    desc: " There are many variations of passages of Lorem Ipsum an available, but the majority have suffered alteration in thes some form, by injected humour, or randomised",
  },
  {
    icon: <i className="fa fa-transgender-alt"></i>,
    title: "Infrastructure Plan",
    desc: " There are many variations of passages of Lorem Ipsum an available, but the majority have suffered alteration in thes some form, by injected humour, or randomised",
  },
  {
    icon: <i className="fa fa-recycle"></i>,
    title: "Firewall Advance",
    desc: " There are many variations of passages of Lorem Ipsum an available, but the majority have suffered alteration in thes some form, by injected humour, or randomised",
  },
];
export default MobileAppDev;
