import { Form, Formik } from "formik";
import React from "react";
import "../../scss/contact/_contactform.scss";

const ContactForm = () => {
  return (
    <section className="contactform_section">
      <div className="container contactform_container">
        <div className="row p-5 contactform_container">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="contactform_inner">
              <div className="contact_title mb-5 text-center">
                <h1>Conatct Us</h1>
              </div>
              <Formik>
                <form id="contact_form">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form_box mb-5">
                        <input type="text" name="name" placeholder="Name" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form_box mb-5">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email Address"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form_box mb-5">
                        <input
                          type="number"
                          name="number"
                          placeholder="Phone Number"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form_box mb-5">
                        <input
                          type="text"
                          name="website"
                          placeholder="Website"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form_box mb-5">
                        <textarea
                          name="message"
                          id="message"
                          cols="20"
                          rows="5"
                          placeholder="Write a Message"
                        ></textarea>
                      </div>
                      <div className="form_btn text-center">
                        <button className="btn" type="submit">
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
