import React from "react";
import main_img from "../../assets/home/home_head/main_img.png";
import diamond from "../../assets/home/home_head/diamond.png";
import arrow_long from "../../assets/home/home_head/arrow-long.png";
import circle_img from "../../assets/home/home_head/circle-img.png";
import arrow from "../../assets/home/home_head/arrow.png";
import envelope from "../../assets/home/home_head/envelope.png";
import userPlus from "../../assets/home/home_head/user-plus.png";
import message from "../../assets/home/home_head/message.png";
import plus from "../../assets/home/home_head/plus.png";

const Home_Head = () => {
  return (
    <>
      <section
        className="homeHead_section d-flex align-items-center slider15"
        id="top"
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-9"
              data-aos="fade-down-right"
            >
              <div className="single_slider">
                <div className="single_content">
                  <div className="single_text">
                    <div className="single_text_inner">
                      <h5 className="mb-4">Digital Agency Based on USA</h5>
                      <h1>Custom Software</h1>
                      <h1>Development Company</h1>
                      <p className="tag_info mt-4">
                        Our highly skilled developers will help you to build the
                        desired software development
                      </p>
                    </div>
                    <div className="slider_button pt-3 d-flex">
                      <div className="button">
                        <a href="#">
                          Get Start Now <i className="fa fa-angle-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="em-hero-inner-shape rotateme">
                  <img src={diamond} alt="" />
                </div>
                <div className="em-hero-inner-shape1 bounce-animate4">
                  <img src={arrow_long} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 side_animation_section" data-aos="fade-up">
              <div className="home_main_img">
                <img className="circle_img" src={main_img} alt="" />
              </div>
              <div class="em-hero-inner-thumb rotateme">
                <img src={circle_img} alt="" />
              </div>
              <div class="em-hero-icon-thumb">
                <img src={arrow} alt="" />
              </div>
              <div class="em-hero-inner-thumb1 bounce-animate3">
                <img src={envelope} alt="" />
              </div>
              <div class="em-hero-inner-thumb2 bounce-animate">
                <img src={userPlus} alt="" />
              </div>
              <div class="em-hero-inner-thumb3 bounce-animate4">
                <img src={message} alt="" />
              </div>
              <div class="em-hero-inner-thumb4 bounce-animate5">
                <img src={plus} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home_Head;
