import React from "react";
import { Link } from "react-router-dom";

const Home_ItSolution = () => {
  const provider = [
    {
      icon: (
        <span className="first">
          <i className="fa-sharp fa-solid fa-code"></i>
        </span>
      ),
      icon_text: "Development",
      title: "Website Applications Development",
      text: "Monotonectally actualize customer methodologie rather than",
    },
    {
      icon: (
        <span className="second">
          <i className="fa-sharp fa-solid fa-code"></i>
        </span>
      ),
      icon_text: "User Experience",
      title: "IOS & Android Apps Interface Design",
      text: "Monotonectally actualize customer methodologie rather than",
    },
    {
      icon: (
        <span className="third">
          <i className="fa-solid fa-people-group"></i>
        </span>
      ),
      icon_text: "Strategy",
      title: "Content Marketing Strategy",
      text: "Monotonectally actualize customer methodologie rather than",
    },
    {
      icon: (
        <span className="fourth">
          <i className="py-1 px-1 fa-solid fa-magnifying-glass"></i>
        </span>
      ),
      icon_text: "Research",
      title: "Market Research and Serveying",
      text: "Monotonectally actualize customer methodologie rather than",
    },
    {
      icon: (
        <span className="fifth">
          <i className="py-1 px-1 fa-solid fa-magnifying-glass"></i>
        </span>
      ),
      icon_text: "Research",
      title: "Business Branding and Identity",
      text: "Monotonectally actualize customer methodologie rather than",
    },
    {
      icon: (
        <span className="sixth">
          <i className="py-1 px-1 fa-solid fa-circle-half-stroke"></i>
        </span>
      ),
      icon_text: "Market Strategy",
      title: "Experience Maping and Developed",
      text: "Monotonectally actualize customer methodologie rather than",
    },
  ];
  return (
    <>
      <section className="ItSolution_section pt-100 pb-160">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center mb-60 mt-3">
                <div className="section_sub_title mb-20">
                  <h5>Featured Services</h5>
                </div>
                <div className="section_main_title upper pb-15">
                  <h1>
                    <b>
                      Techno Provide Best{" "}
                      <span className="title_color">IT Solutions</span>
                    </b>
                  </h1>
                </div>
                <div className="section_content_text upper">
                  <p className="text-center">
                    Completely strategize client-centric niche markets for go
                    forward plat Phosfluorescently iterate efficient internal
                    organic.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {provider.map((item) => {
              return (
                <div className="col-sm-6 col-md-6 col-lg-4">
                  <div className="em-service-single-box">
                    <div className="em-service-box-inner">
                      <div className="em-service-content">
                        <div className="em-service-icon">
                          <div className="em-icon">{item.icon}</div>
                          <div className="em-icon-title">
                            <h4>{item.icon_text}</h4>
                          </div>
                        </div>
                        <div className="em-service-title">
                          <h2>{item.title}</h2>
                        </div>
                        <div className="em-service-text">
                          <p>{item.text}</p>
                        </div>
                        <div className="em-service-button">
                          <a href="#">
                            Learn More{" "}
                            <i className="fa-solid fa-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};
export default Home_ItSolution;
