import React from "react";
import "../../scss/contact/_contactmap.scss";

const ContactMap = () => {
  const location = {
    address: "1600 Amphitheatre Parkway, Mountain View, california.",
    lat: 37.42216,
    lng: -122.08427,
  };
  return (
    <section className="google_map_area">
      <div className="row-fluid">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.789267176314!2d72.497061115376!3d22.99477542313575!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e84ee45695b49%3A0x667b18e9feb9ddc2!2sShine%20Infosoft!5e0!3m2!1sen!2sin!4v1676381467608!5m2!1sen!2sin"
            height="450"
            style={{border:"0",width:"100%"}}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default ContactMap;
