import React, { useState } from "react";
import home_port1 from "../../assets/home/home_caseStudy/home_port1.png";
import home_port2 from "../../assets/home/home_caseStudy/home_port2.png";
import home_port3 from "../../assets/home/home_caseStudy/home_port3.png";
import home_port4 from "../../assets/home/home_caseStudy/home_port4.png";
import home_port5 from "../../assets/home/home_caseStudy/home_port5.png";
import home_port6 from "../../assets/home/home_caseStudy/home_port6.png";
import test1 from "../../assets/home/home_caseStudy/test1.png";
import people from "../../assets/home/home_caseStudy/people.png";
import ReactOwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Home_caseStudy = () => {
  const [selectList, setSelectList] = useState();
  const [filterProduct, setFilterProduct] = useState([]);

  const checkListItem = (i, itemName) => {
    setSelectList(i);
    const filterData = provider.filter((item) => {
      return item.title_identy === itemName;
    });
    setFilterProduct(filterData);
  };

  return (
    <>
      <section className="caseStudy_section pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="mb-30 text-left mt-3">
                <div className="section_sub_title mb-3">
                  <h5>Case Studies</h5>
                </div>
                <div className="section_main_title mb-3">
                  <h1>
                    Techno Recently <span>Business</span>
                  </h1>
                  <h1>Case Studies</h1>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="caseStudy_nav">
                <div className="caseStudy_nav_menu">
                  <ul className="menu-filtering">
                    {listItems.map((item, i) => {
                      return (
                        <li
                          key={i}
                          className={
                            selectList === i ? "current_menu_item" : ""
                          }
                          data-filter="*"
                          onClick={() => checkListItem(i, item)}
                        >
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {filterProduct.length >= 1
              ? filterProduct.map((item) => {
                  return (
                    <CommonDivSection
                      src={item.image}
                      icon={item.icon}
                      text={item.icon_text}
                      title={item.title}
                    />
                  );
                })
              : provider.map((item) => {
                  return (
                    <CommonDivSection
                      src={item.image}
                      icon={item.icon}
                      text={item.icon_text}
                      title={item.title}
                    />
                  );
                })}
          </div>
        </div>
        <div className="testimonial-area pt-130 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-sm-8 col-md-8 d-flex justify-content-center" data-aos="fade-right">
                <div className="testimonial-single-box1">
                  <img src={test1} alt="" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6" data-aos="fade-left">
                <div
                  className="section_title text_left white mb-30 mt-3"
                  data-wow-delay=".4"
                >
                  <div className="section_sub_title mb-3">
                    <h5> Client Testimonial </h5>
                  </div>
                  <div className="section_main_title upper">
                    <h1> Techno all Over World 23M+ </h1>
                    <h1> Satisfied Customers </h1>
                  </div>
                </div>
                <div className="row">
                  <div className="blog-list owl-carousel owl-loaded owl-drag owl-grab">
                    <ReactOwlCarousel
                      items={1}
                      className="owl-theme mb-30"
                      loop
                      margin={10}
                      nav
                    >
                      {testiList.map((item) => {
                        return (
                          <div className="item cloned">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                              <div
                                className="em-testimoinal-single-box white wow fadeInUp animated"
                                data-wow-delay=".5"
                              >
                                <div className="em-testimonial-title">
                                  <h6>“{item.testiTitle}”</h6>
                                  <p>{item.testiDesc}</p>
                                </div>
                                <div className="testi-people">
                                  <div className="people-thumb">
                                    <img src={people} alt="" />
                                  </div>
                                  <div className="people-title">
                                    <h4>{item.testAuthor}</h4>
                                    <p>{item.authorPosition}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </ReactOwlCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const listItems = ["All", "Branding", "Design", "Dashboard", "Logo"];
const provider = [
  {
    image: home_port1,
    icon: <i className="fa-solid fa-code"></i>,
    icon_text: "Development",
    title: "Food App Development",
    title_identy: "Branding",
  },
  {
    image: home_port2,
    icon: <i className="fa-solid fa-code"></i>,
    icon_text: "Product Design",
    title: "Sales ERP Software",
    title_identy: "Design",
  },
  {
    image: home_port3,
    icon: <i className="fa-solid fa-code"></i>,
    icon_text: "Development",
    title: "Business Admin Panel",
    title_identy: "Design",
  },
  {
    image: home_port4,
    icon: <i className="fa-solid fa-code"></i>,
    icon_text: "Development",
    title: "Restaurent App Design",
    title_identy: "Dashboard",
  },
  {
    image: home_port5,
    icon: <i className="fa-solid fa-code"></i>,
    icon_text: "Product Design",
    title: "Figma Dashboard Design",
    title_identy: "Dashboard",
  },
  {
    image: home_port6,
    icon: <i className="fa-solid fa-code"></i>,
    icon_text: "Development",
    title: "Awesome Logo Design",
    title_identy: "Logo",
  },
];
const CommonDivSection = ({ src, icon, text, title }) => {
  return (
    <div className="col-lg-4 col-md-6">
      <div className="single_portfolio">
        <div className="single_portfolio_thumb1">
          <img variant="top" src={src} />
        </div>
        <div className="single_portfolio_content1">
          <div className="single_portfolio_icon">
            <a
              className="portfolio-icon venobox vbox-item"
              data-gall="myportfolio"
              href="assets/images/portfolio/p1.jpg"
            >
              <i className="fa-solid fa-arrow-right"></i>
            </a>
          </div>
          <div className="single_portfolio_content_inner">
            <div className="portfolio-icon">
              <div className="port-icon-thumb">{icon}</div>
              <div className="port-icon-title">
                <p>{text}</p>
              </div>
            </div>
            <h2>
              <a href="portfolio-details.html">{title}</a>
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};
const testiList = [
  {
    testiTitle: "I am Very Happy for the Awesome Service!",
    testiDesc:
      "Completely strategize client-centric niche markets for forward platform Phosfluorescently iterate efficient internal or organic sources wherea revolutionary service. Objectively syndicate crosplatform data without scalable deliverables. Holisticly enhance.							",
    testiImage: people,
    testAuthor: "Martin Collings",
    authorPosition: "CEO, Founder",
  },
  {
    testiTitle: "I am Very Happy for the Awesome Service!",
    testiDesc:
      "Completely strategize client-centric niche markets for forward platform Phosfluorescently iterate efficient internal or organic sources wherea revolutionary service. Objectively syndicate crosplatform data without scalable deliverables. Holisticly enhance.							",
    testiImage: people,
    testAuthor: "Martin Collings",
    authorPosition: "CEO, Founder",
  },
  {
    testiTitle: "I am Very Happy for the Awesome Service!",
    testiDesc:
      "Completely strategize client-centric niche markets for forward platform Phosfluorescently iterate efficient internal or organic sources wherea revolutionary service. Objectively syndicate crosplatform data without scalable deliverables. Holisticly enhance.							",
    testiImage: people,
    testAuthor: "Martin Collings",
    authorPosition: "CEO, Founder",
  },
];
export default Home_caseStudy;
