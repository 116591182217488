import React from "react";
import feature1 from "../../../assets/services/feature1.jpg";
import FurtherInformation from "../../../commoncomponents/commmonBodyPart/furtherInformation";
import PageTitle from "../../../commoncomponents/commonPageTitle/pageTitle";
const ExclusiveServices = () => {
  const service = [
    {
      icon: <i className="fa-solid fa-desktop"></i>,
      title: "IT Management",
      desc: "Porem asum molor sit amet, consectetur adipiscing do miusmod tempor.",
      inner_title: "Free Online Course",
      inner_desc:
        "Porem asum molor sit amet, consectetur adipiscing do miusmod tempor.",
    },
    {
      icon: <i className="fa-solid fa-laptop"></i>,
      title: "Data Security",
      desc: "Porem asum molor sit amet, consectetur adipiscing do miusmod tempor.",
      inner_title: "Interior Design",
      inner_desc:
        "Porem asum molor sit amet, consectetur adipiscing do miusmod tempor.",
    },
    {
      icon: <i className="fa-solid fa-laptop"></i>,
      title: "Business Reform",
      desc: "Porem asum molor sit amet, consectetur adipiscing do miusmod tempor.",
      inner_title: "Online Marketing",
      inner_desc:
        "Porem asum molor sit amet, consectetur adipiscing do miusmod tempor.",
    },
    {
      icon: <i className="fa-solid fa-laptop"></i>,
      title: "IT Design",
      desc: "Porem asum molor sit amet, consectetur adipiscing do miusmod tempor.",
      inner_title: "Online Product",
      inner_desc:
        "Porem asum molor sit amet, consectetur adipiscing do miusmod tempor.",
    },
    {
      icon: <i className="fa-solid fa-laptop"></i>,
      title: "Firewall Advancement",
      desc: "Porem asum molor sit amet, consectetur adipiscing do miusmod tempor.",
      inner_title: "Online Product",
      inner_desc:
        "Porem asum molor sit amet, consectetur adipiscing do miusmod tempor.",
    },
    {
      icon: <i className="fa-solid fa-laptop"></i>,
      title: "Infrastructure Plan",
      desc: "Porem asum molor sit amet, consectetur adipiscing do miusmod tempor.",
      inner_title: "Online Product",
      inner_desc:
        "Porem asum molor sit amet, consectetur adipiscing do miusmod tempor.",
    },
  ];
  return (
    <>
      <section className="ExclusiveServicesSection pt-85 pb-10">
        <div className="container">
          <PageTitle
            h6_title="IT SERVICES"
            h1_title="Preparing For Your Success,"
            h1_second_title="We Provide Truly"
            span_text="Prominent IT Solutions."
          />
          <div className="row">
            {service.map((item) => {
              return (
                <div className="col-lg-4 col-md-6 col-sm-12 col-xs-6">
                  <div className="techno_flipbox mb-30">
                    <div className="techno_flipbox_font">
                      <div className="techno_flipbox_inner">
                        <div className="techno_flipbox_icon">
                          <div className="icon">{item.icon}</div>
                        </div>
                        <div className="flipbox_title">
                          <h3>{item.title}</h3>
                        </div>
                        <div className="flipbox_desc">
                          <p>{item.desc}</p>
                        </div>
                      </div>
                    </div>
                    <div className="techno_flipbox_back">
                      {/* <img src={feature1} alt="" /> */}
                      <div className="techno_flipbox_inner">
                        <div className="flipbox_title">
                          <h3>{item.inner_title}</h3>
                        </div>
                        <div className="flipbox_desc">
                          <p>{item.inner_desc}</p>
                        </div>
                        <div className="flipbox_button">
                          <a href="">
                            Read More
                            <i className="fa fa-angle-double-right"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <FurtherInformation />
      </section>
    </>
  );
};

export default ExclusiveServices;
