import React from "react";

const CareerDetailcomponent = () => {
  return (
    <>
      <section className="serviceDetails_section pt-90 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="service-main-details">
                    <div className="service-main-details-inner">
                      <div className="service-main-details-content-text pb-4">
                        <h3>Web Design & Developer</h3>
                        <h4>Dream IT Solution</h4>
                      </div>
                      <div className="service-main-details-content-text pb-4">
                        <h5>Vacancy:</h5>
                        <p>Not specific</p>
                      </div>
                      <div className="service-main-details-content-text pb-50">
                        <h5>Job Context</h5>
                        <p>
                          One Information and Communications Technology Limited
                          (One ICT) is the Concern of National Agri Care Group
                          (NACG), which is a large group of company in
                          Bangladesh. One ICT is seeking a Software Engineer for
                          Web developer with experience building services using
                          web design and development technologies.
                        </p>
                      </div>
                      <div className="service-main-details-content-text pb-50">
                        <h5>Job Responsibilities</h5>
                        <ul>
                          <li>
                            Must have clear understanding on Object Oriented
                            Programming and Design Principles.
                          </li>
                          <li>
                            Design JavaScript concepts and design patterns.
                            Knowledge on frameworks such as React, Express,
                            Node.js.
                          </li>
                          <li>
                            Design and development of web service technologies
                            such as REST, or JSON, PHP, Laravel, .Net core,
                            WordPress.
                          </li>
                          <li>
                            Proficiency in data storage solutions including both
                            SQL (e.g. MySQL, Postgres, SQL Server) and NoSQL
                            databases (e.g. MongoDB).
                          </li>
                          <li>
                            Knowledge on Software Development Architecture,
                            Software Development Life Cycle.
                          </li>
                          <li>
                            Experience using Git and GitHub/Others for source
                            control. Your Projects link is Plus.
                          </li>
                          <li>
                            Ability to work on multiple projects by taking full
                            responsibility and leadership role.
                          </li>
                          <li>
                            Quick learner & good problem-solving skills, capable
                            of working under tight schedule.
                          </li>
                          <li>
                            Must be honest, loyal, ethical and have good
                            manners.
                          </li>
                          <li>Must be a hard worker.</li>
                        </ul>
                      </div>
                      <div className="service-main-details-content-text pb-2">
                        <h5>Employment Status</h5>
                        <ul>
                          <li>Full-time</li>
                        </ul>
                      </div>
                      <div className="service-main-details-content-text pb-2">
                        <h5>Employment Status</h5>
                        <ul>
                          <li>Work at office</li>
                        </ul>
                      </div>
                      <div className="service-main-details-content-text pb-2">
                        <h5>Educational Requirements</h5>
                        <ul className="service_ul">
                          <li>
                            Bachelor of Science (BSc) in Computer Science
                            Engineering, Bachelor of Computer Application (BCA)
                            in CSE
                          </li>
                          <li>
                            Skills Required: CSS, HTML, PHP (OOP), WordPress
                          </li>
                        </ul>
                      </div>
                      <div className="service-main-details-content-text pb-2">
                        <h5>Experience Requirements</h5>
                        <ul className="service_ul">
                          <li>2 to 6 year(s)</li>
                          <li>
                            The applicants should have experience in the
                            following area(s):
                            <ul>
                              <li>PHP Laravel, Web Developer/ Web Designer</li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                      <div className="service-main-details-content-text pb-2">
                        <h5>Additional Requirements</h5>
                        <ul className="service_ul">
                          <li>Age 24 to 38 years</li>
                          <li>Both males and females are allowed to apply</li>
                          <li>Age 24 to 38 Years</li>
                          <li>Both males and females are allowed to apply</li>
                          <li>
                            2 years+ of experience in web design and
                            development.
                          </li>
                          <li>
                            Analyze keywords and SEO techniques used by
                            competitors, develop and implement Content
                            strategies, Google Adsense, Adwords, and Analytics.
                          </li>
                          <li>
                            Audience targeting, analyzing, planning and using
                            SEO and SEM, On-Page SEO, Off-Page SEO, keyword
                            research, content marketing, Google AdWords, Google
                            Analytics, Adsense, Youtube Marketing, Blogging,
                            Inbound Marketing, Outbound Marketing, etc.
                          </li>
                          <li>Knowledge of Server/cPanel/Hosting</li>
                          <li>
                            API Integration like Youtube, Facebook, Google, SMS,
                            and Payment Gateway.
                          </li>
                          <li>
                            Leadership - Intermediate leadership skills with the
                            ability to help create a safe environment for others
                            to learn and grow as engineers and a proven track
                            record of self-motivation in identifying opp.
                          </li>
                        </ul>
                      </div>
                      <div className="service-main-details-content-text pb-2">
                        <h5>Job Location</h5>
                        <p>Dhaka</p>
                      </div>
                      <div className="service-main-details-content-text pb-2">
                        <h5>Salary</h5>
                        <p>40000-50000</p>
                      </div>
                      <div className="service-main-details-content-text pb-2">
                        <h5>Compensation & Other Benefits</h5>
                        <ul className="service_ul">
                          <li>
                            Mobile bill, Tour allowance, Weekly 2 holidays
                          </li>
                          <li>Lunch Facilities: Partially Subsidize</li>
                          <li>Salary Review: Yearly</li>
                          <li>Festival Bonus: 2</li>
                        </ul>
                      </div>
                      <div className="service-bottom-details-content-text pt-4 text-center pb-2">
                        <h4>Read Before Apply</h4>
                        <h5>
                          <span>*Photograph</span> must be enclosed with the
                          resume.
                        </h5>
                        <h6>Apply Procedures</h6>
                        <a href="#">Apply Online</a>
                      </div>
                      <div className="service-bottom-details-content-text pt-4 text-center pb-2">
                        <h4>Email</h4>
                        <p>
                          Send your CV to{" "}
                          <span className="example">example@gmail.com</span> or
                          to example@hotmail.com
                        </p>
                        <h6>Application Deadline : 23 Apr 2022</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12">
              <div className="jobSummary_head">
                <h5>Job Summary</h5>
              </div>
              <div className="jobSummary_main">
                <ul>
                  <li>
                    <b>Published on:</b> 24 Mar 2022
                  </li>
                  <li>Employment Status: Full-time</li>
                  <li>Experience: 2 to 6 year(s)</li>
                  <li>Gender: Both males and females are allowed to apply</li>
                  <li>Age: Age 24 to 38 years</li>
                  <li>Job Location: Dhaka</li>
                  <li>Salary: Negotiable</li>
                  <li>Application Deadline: 23 Apr 2022</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CareerDetailcomponent;
