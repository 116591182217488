import React from "react";
import Banner from "../commoncomponents/commonhead/BannerHead";
import ExclusiveServices from "../components/service/webappdevelopment/exclusiveServices";
import ServiceTestimonial from "../components/service/webappdevelopment/serviceTestimonial";

const Service = () => {
  return (
    <>
      <Banner title="IT Service" />
      <ExclusiveServices />
      <ServiceTestimonial />
    </>
  );
};

export default Service;
