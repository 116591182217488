import React from "react";
import blog1 from "../../assets/blog/blog3.jpg";
import blog2 from "../../assets/blog/blog2.jpg";
import testi2 from "../../assets/blog/testi2.png";
import testi1 from "../../assets/blog/testi1.png";
import testi3 from "../../assets/blog/testi3.png";

const BlogDescription = () => {
  return (
    <>
      <section className="blog_description_section pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="blog_description_details">
                    <div className="blog_desc_dtl_thumb">
                      <img src={blog1} alt="#" />
                    </div>
                    <div className="blog_desc_dtl_content">
                      <div className="blog_desc_dtl_top pt-2">
                        <span>Business</span>
                      </div>
                      <h2>
                        Business contents insurance is a type of business
                        insurance that can protect
                      </h2>
                      <div className="blog_desc_dtl_meta">
                        <div className="blog_desc_dtl_meta-left">
                          <span>
                            <i className="fa fa-calendar"></i>10 Mar 2023
                          </span>
                          <span>
                            <i className="fa-regular fa-comments"></i>20
                            Comments
                          </span>
                          <span>
                            <i className="fa-solid fa-thumbs-up"></i>39 Like
                          </span>
                        </div>
                      </div>
                      <p>
                        Lorem ipsum dolor sit amet consectet adipisie cing elit
                        sed eiusmod tempor incididunt on labore et dolore. Ut
                        enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo.
                      </p>
                      <p className="mt-3">
                        Bccaecat cupidatat non proident, sunt in culpa qui
                        officia deserunt mollit anim id est there laborum. Sed
                        ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium ware doloremque laudantium, totam
                        rem aperiam, eaque ipsa quae ab illo inventore veritatis
                        et quasi architecto beatae vitae dicta sunt explicabo.
                        Nemo enim ipsam voluptatem quia on voluptas sit
                        aspernatur aut odit aut fugit, sed quia consequuntur
                        magni dolores eos query ratione voluptatem sequi
                        nesciunt. Neque porro quisquam
                      </p>
                      <p className="mt-3">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tempor dolor an incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud there exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure poor
                        dolor in reprehenderit in voluptate velit esse cillum
                      </p>
                      <div className="blog_dtl_thumb pt-3">
                        <img src={blog2} alt="#" />
                      </div>
                      <h3>A cleansing hot shower or bath</h3>
                      <p>
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text. It has roots in a piece of classNameical on
                        Latin literature from 45 BC, making it over 2000 years
                        old. Richard McClintock, a Latin professor at thes
                        Hampden-Sydney College in Virginia, looked up one of the
                        more obscure Latin words, consectetur, from a Lorem
                        Ipsum passage, and going through the cites
                      </p>
                      <blockquote>
                        Richard McClintock, a Latin professor at thes
                        Hampden-Sydney College in Virginia, looked up one of the
                        more obscure Latin words, consectetur, from a Lorem
                        Ipsum passage, and going through the cites dorem awesme
                        loren very creative
                        <p className="signatures">
                          <span>Mr. Aleck Jonder</span>
                        </p>
                      </blockquote>
                      <div className="blog_dt_title mb-4">
                        <h4>Setting the mood with incense</h4>
                      </div>
                      <p>
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text. It has roots in a piece of classNameical on
                        Latin literature from 45 BC, making it over 2000 years
                        old. Richard McClintock, a Latin professor at thes
                        Hampden-Sydney College in Virginia, looked up one of the
                        more obscure Latin words, consectetur, from a Lorem
                        Ipsum passage, and going through the cites
                      </p>
                      <div className="blog_details_dtn_icon mt-3">
                        <a href="#">
                          <i className="fa-brands fa-facebook-f"></i>
                        </a>
                        <a href="#">
                          <i className="fa-brands fa-linkedin-in"></i>
                        </a>
                        <a href="#">
                          <i className="fa-brands fa-twitter"></i>
                        </a>
                        <a href="#">
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                        <a href="#">
                          <i className="fa-brands fa-pinterest"></i>
                        </a>
                        <a href="#">
                          <i className="fa-brands fa-reddit"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="recent_post_dtls mt-5">
                    <div className="blog_dt_title mb-4">
                      <h4>Recent Post</h4>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="single_blog mb-4">
                          <div className="single_blog_thumb pb-4">
                            <a href="blog-details.html">
                              <img src={blog1} alt="" />
                            </a>
                          </div>
                          <div className="single_blog_content pl-4 pr-4">
                            <div className="techno_blog_meta">
                              <a href="#">Techno </a>
                              <span className="meta-date pl-3">
                                January 3, 2023
                              </span>
                            </div>
                            <div className="blog_page_title pb-1">
                              <h3>
                                <a href="blog-details.html">
                                  The five devices you need to work anytime
                                </a>
                              </h3>
                            </div>
                            <div className="blog_description">
                              <p>
                                Lorem ipsum dolor sit amet consectet adipisie
                                cing elit sed eiusmod tempor incididunt on
                                labore et dolore.{" "}
                              </p>
                            </div>
                            <div className="blog_page_button pb-4 mt-2">
                              <a href="blog-details.html">
                                Read More{" "}
                                <i className="fa fa-long-arrow-right"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="single_blog mb-4">
                          <div className="single_blog_thumb pb-4">
                            <a href="blog-details.html">
                              <img src={blog2} alt="" />
                            </a>
                          </div>
                          <div className="single_blog_content pl-4 pr-4">
                            <div className="techno_blog_meta">
                              <a href="#">Techno </a>
                              <span className="meta-date pl-3">
                                December 3, 2023
                              </span>
                            </div>
                            <div className="blog_page_title pb-1">
                              <h3>
                                <a href="blog-details.html">
                                  How to learn PHP 10 tips to get you started
                                </a>
                              </h3>
                            </div>
                            <div className="blog_description">
                              <p>
                                Lorem ipsum dolor sit amet consectet adipisie
                                cing elit sed eiusmod tempor incididunt on
                                labore et dolore.{" "}
                              </p>
                            </div>
                            <div className="blog_page_button pb-4 mt-2">
                              <a href="blog-details.html">
                                Read More{" "}
                                <i className="fa fa-long-arrow-right"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="blog_comments_section mt-5">
                    <div className="comments_ttl">
                      <h3>3 Comments</h3>
                    </div>
                    <div className="single_comments">
                      <div className="comments_thumb">
                        <img src={testi2} alt="" />
                      </div>
                      <div className="commentst_content">
                        <div className="post_meta">
                          <span className="badmin">John Dome</span>
                          <span className="badmin">
                            <i className="fa fa-clock-o"></i> Jan 06 2023
                          </span>
                          <a href="">
                            <span className="commentst_meta_reply">
                              <i className="fa fa-reply"></i>Reply
                            </span>
                          </a>
                        </div>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolor magna ali Ut enim ad minim veniam, quis nostrud
                          exercitation .
                        </p>
                      </div>
                      <div className="single_commentst_inner">
                        <div className="comments_thumb">
                          <img src={testi1} alt="" />
                        </div>
                        <div className="commentst_content">
                          <div className="post_meta">
                            <span className="badmin">John Dome</span>
                            <span className="badmin">
                              <i className="fa fa-clock-o"></i> Jan 06 2023
                            </span>
                            <a href="">
                              <span className="commentst_meta_reply">
                                <i className="fa fa-reply"></i>Reply
                              </span>
                            </a>
                          </div>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolor magna ali Ut enim ad minim veniam, quis
                            nostrud exercitation .
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="single_comments">
                      <div className="comments_thumb">
                        <img src={testi3} alt="" />
                      </div>
                      <div className="commentst_content">
                        <div className="post_meta">
                          <span className="badmin">John Dome</span>
                          <span className="badmin">
                            <i className="fa fa-clock-o"></i> Jan 06 2023
                          </span>
                          <a href="">
                            <span className="commentst_meta_reply">
                              <i className="fa fa-reply"></i>Reply
                            </span>
                          </a>
                        </div>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolor magna ali Ut enim ad minim veniam, quis nostrud
                          exercitation .
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="blog_reply_coment_dtl mt-5">
                    <div class="reply_ttl">
                      <h3>Leave Comments</h3>
                    </div>
                    <div class="row">
                      <div class="col-md-12 col-sm-12 col-xs-12">
                        <div class="contact_from">
                          <form
                            id="contact_form"
                            action="https://formspree.io/f/myyleorq"
                            method="POST"
                          >
                            <div class="row">
                              <div class="col-lg-6">
                                <div class="form_box mb-30">
                                  <input
                                    type="text"
                                    name="name"
                                    placeholder="Name"
                                  />
                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="form_box mb-30">
                                  <input
                                    type="email"
                                    name="email"
                                    placeholder="Email Address"
                                  />
                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="form_box mb-30">
                                  <input
                                    type="text"
                                    name="phone"
                                    placeholder="Phone Number"
                                  />
                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="form_box mb-30">
                                  <input
                                    type="text"
                                    name="web"
                                    placeholder="Website"
                                  />
                                </div>
                              </div>

                              <div class="col-lg-12">
                                <div class="form_box mb-30">
                                  <textarea
                                    name="message"
                                    id="message"
                                    cols="30"
                                    rows="10"
                                    placeholder="Write a Message"
                                  ></textarea>
                                </div>
                                <div class="quote_btn text-center">
                                  <button class="btn" type="submit">
                                    Send Message
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                          <div id="status"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-5 col-sm-12 blog_desc_sidebar_right"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDescription;
