import React from "react";
import recent1 from "../../assets/footer/recent1.jpg";
import recent3 from "../../assets/footer/recent3.jpg";
import SHINE_LOGO_white_new from "../../assets/footer/SHINE_LOGO_white_new.png";

const Footer = () => {
  return (
    <>
      <section className="footer_main_section footer_bg pt-95">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="widget widgets-company-info">
                <div className="footer-bottom-logo pb-40">
                  <img src={SHINE_LOGO_white_new} alt="" />
                </div>
                <div className="company-info-desc">
                  <p>
                    Condimentum rhoncus, sem quam semper libero, sit amet
                    adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel,
                    luctus.
                  </p>
                </div>
                <div className="follow-company-info pt-3">
                  <div className="follow-company-text mr-3">
                    <a href="#">
                      <p>Follow Us</p>
                    </a>
                  </div>
                  <div className="follow-company-icon">
                    <a href="#">
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                    <a href="#">
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                    <a href="#">
                      <i className="fa-brands fa-linkedin"></i>
                    </a>
                    <a href="#">
                      <i className="fa-brands fa-skype"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="widget widget-nav-menu">
                <h4 className="widget-title pb-4">Our Services</h4>
                <div className="menu-quick-link-container ml-4">
                  <ul id="menu-quick-link" className="menu">
                    <li>
                      <a href="#">Marketing Strategy</a>
                    </li>
                    <li>
                      <a href="#">Interior Design</a>
                    </li>
                    <li>
                      <a href="#">Digital Services</a>
                    </li>
                    <li>
                      <a href="#">Product Selling</a>
                    </li>
                    <li>
                      <a href="#">Product Design</a>
                    </li>
                    <li>
                      <a href="#">Social Marketing</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="widget widgets-company-info">
                <h3 className="widget-title pb-4">Company Address</h3>
                <div className="company-info-desc">
                  <p>
                    Porem awesome dolor sitework amet, consetur acing elit, sed
                    do eiusmod ligal
                  </p>
                </div>
                <div className="footer-social-info">
                  <p>
                    <span>Address :</span> A - 501, SiddhiVinayak Towers, Nr.
                    D.A.V. School, Off, Sarkhej - Gandhinagar Hwy, Makarba,
                    Ahmedabad, Gujarat 380051.
                  </p>
                </div>
                <div className="footer-social-info">
                  <p>
                    <span>Phone :</span> 079-40396030
                  </p>
                </div>
                <div className="footer-social-info">
                  <p>
                    <span>Email :</span> info@shineinfosoft.in
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div id="em-recent-post-widget">
                <div className="single-widget-item">
                  <h4 className="widget-title pb-3">Popular Post</h4>
                  <div className="recent-post-item active pb-3">
                    <div className="recent-post-image mr-3">
                      <a href="#">
                        <img width="80" height="80" src={recent1} alt="" />
                      </a>
                    </div>
                    <div className="recent-post-text">
                      <h6>
                        <a href="#">Tiktok Illegally collecting data sharing</a>
                      </h6>
                      <span className="rcomment">December 12, 2023</span>
                    </div>
                  </div>
                  <div className="recent-post-item pt-1">
                    <div className="recent-post-image mr-3">
                      <a href="#">
                        <img width="80" height="80" src={recent3} alt="" />
                      </a>
                    </div>
                    <div className="recent-post-text">
                      <h6>
                        <a href="#">How can use our latest news by</a>
                      </h6>
                      <span className="rcomment">December 15, 2023</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row footer-bottom mt-70 pt-3 pb-1">
            <div className="col-lg-6 col-md-6">
              <div className="footer-bottom-content">
                <div className="footer-bottom-content-copy">
                  <p>Copyright © ShineInfosoft all rights reserved.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="footer-bottom-right">
                <div className="footer-bottom-right-text">
                  <a className="absod" href="#">
                    Privacy Policy{" "}
                  </a>
                  <a href="#"> Terms &amp; Conditions</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
