import React from "react";
import ReactOwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import testi1 from "../../../assets/services/webappdevelopment/testi1.jpg";
import testi2 from "../../../assets/services/webappdevelopment/testi2.jpg";
import testi3 from "../../../assets/services/webappdevelopment/testi3.jpg";
import ReactStars from "react-rating-stars-component";
import BodyBottomCrousal from "../../../commoncomponents/commonBodyBottomCrousal/BodyBottomCrousal";

const ServiceTestimonial = () => {
  return (
    <>
      <section className="testimonial_area pt-90 pb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="row">
                <div className="blog-list owl-carousel owl-loaded owl-drag owl-grab">
                  <ReactOwlCarousel
                    className="owl-theme mb-30"
                    loop
                    margin={10}
                  >
                    {crouselService.map((item, i) => {
                      return (
                        <div key={i} className="item cloned">
                          <div className="col-lg-12">
                            <div className="single_testimonial mt-3 mb-5">
                              <div className="single_testimonial_content">
                                <div className="single_testimonial_content_text mb-4">
                                  <p>{item.desc}</p>
                                </div>
                                <div className="single_testimonial_thumb mt-2 mr-3">
                                  <img src={item.image} alt="" />
                                </div>
                                <div className="single_testimonial_content_title mt-4">
                                  <div className="reviews_rating">
                                    <div className="testi-star">
                                      {item.stars}
                                    </div>
                                  </div>
                                  <h4>{item.author}</h4>
                                  <span>{item.author_post}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </ReactOwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BodyBottomCrousal />
    </>
  );
};
const crouselService = [
  {
    stars: (
      <ReactStars size={24} value={2} activeColor="#0c5adb" edit="false" />
    ),
    author: "Jamal Vuiyan",
    author_post: "Developer",
    image: testi1,
    desc: "Dignissim per dis dignissim mi nibh a parturient habitasse suspendisse ut a feugiat morbi neque don't is tortor.Tellus volutpat scelerisque techno tempor.",
  },
  {
    stars: (
      <ReactStars size={24} value={3} activeColor="#0c5adb" edit="false" />
    ),
    author: "Abraham Jhone",
    author_post: "IT Manager",
    image: testi3,
    desc: "Dignissim per dis dignissim mi nibh a parturient habitasse suspendisse ut a feugiat morbi neque don't is tortor.Tellus volutpat scelerisque techno tempor.",
  },
  {
    stars: (
      <ReactStars size={24} value={4} activeColor="#0c5adb" edit="false" />
    ),
    author: "Angel Jessika",
    author_post: "Developer",
    image: testi2,
    desc: "Dignissim per dis dignissim mi nibh a parturient habitasse suspendisse ut a feugiat morbi neque don't is tortor.Tellus volutpat scelerisque techno tempor.",
  },
  {
    stars: (
      <ReactStars size={24} value={2} activeColor="#0c5adb" edit="false" />
    ),
    author: "Abraham Jhone",
    author_post: "IT Manager",
    image: testi3,
    desc: "Dignissim per dis dignissim mi nibh a parturient habitasse suspendisse ut a feugiat morbi neque don't is tortor.Tellus volutpat scelerisque techno tempor.",
  },
];
export default ServiceTestimonial;
