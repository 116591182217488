import React, { useState } from "react";
import blog1 from "../../assets/home/home_blog/blog1.png";
import blog2 from "../../assets/home/home_blog/blog2.png";
import blog3 from "../../assets/home/home_blog/blog3.png";
import blog4 from "../../assets/home/home_blog/blog4.png";
import target from "../../assets/home/home_blog/target.png";
import messege from "../../assets/home/home_blog/messege.png";
import ReactOwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const options = {
  margin: 30,
  responsiveclassName: true,
  nav: true,
  autoplay: true,
  // navText: ["Prev", "Next"],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 2,
    },
    700: {
      items: 3,
    },
    1000: {
      items: 3,
    },
  },
};
const Home_Blog = () => {
  return (
    <>
      <section className="blog-area blog-new pb-40">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section_title text_left mb-50 mt-3 wow fadeInDown animated"
                data-wow-delay=".3"
              >
                <div className="section_sub_title mb-3">
                  <h5> Our Blog </h5>
                </div>
                <div className="section_main_title upper">
                  <h1>
                    Latest from <span>Our Blog</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="blog-list owl-carousel owl-loaded owl-drag owl-grab">
              <ReactOwlCarousel
                className="owl-theme mb-30"
                loop
                margin={10}
                nav
                {...options}
              >
                {owlItem.map((item, i) => {
                  return (
                    <div key={i} className="item cloned">
                      <div className="col-lg-12">
                        <div
                          className="single-blog-box wow fadeInLeft animated animated"
                          data-wow-delay=".4"
                        >
                          <div className="single-blog-thumb">
                            <img src={item.itemImg} alt="" />
                          </div>
                          <div className="em-blog-content">
                            <div className="meta-blog-text">
                              <i className="fa-solid fa-calendar-check"></i>
                              <p> {item.itemDate}</p>
                            </div>
                            <div className="em-blog-title">
                              <h2>
                                {" "}
                                <a href="#">{item.itemTitle}</a>{" "}
                              </h2>
                            </div>
                            <div className="em-blog-text">
                              <p>{item.itemDesc}</p>
                            </div>
                            <div className="em-blog-icon">
                              <div className="em-blog-thumb">
                                <img src={target} alt="" />
                              </div>
                              <div className="em-blog-icon-title">
                                <h6> {item.itemAuthor}</h6>
                              </div>
                            </div>
                            <div className="blog-button">
                              <a href="#">
                                {item.itemMore}{" "}
                                <i className="fa-solid fa-arrow-right"></i>{" "}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </ReactOwlCarousel>
            </div>
          </div>
        </div>
        <div className="subscribe_area style_three pb-100">
          <div className="container">
            <div
              className="row sbc_bg_box wow fadeInDown animated"
              data-wow-delay=".4"
            >
              <div className="col-lg-8 col-md-8">
                <div className="subscribe_bg_box">
                  <div className="single_subscribe_contact">
                    <div className="subscribe_content_title white pb-15">
                      <h2>Subscribe Our Newsletter</h2>
                      <p>Subscribe to Keep Up to Date with Everything Techno</p>
                    </div>
                    <form action="#">
                      <div className="subscribe_form">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className="form-control"
                          required=""
                          data-error="Please enter your email"
                          placeholder="Enter Your Email"
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                      <div className="subscribe_form_send">
                        <button
                          type="submit"
                          className="btn subscribe_form_send_button"
                        >
                          Subscribe
                        </button>
                        <div
                          id="msgSubmit"
                          className="h3 text-center hidden"
                        ></div>
                        <div className="clearfix"></div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="subscribe-thumb">
                  <img src={messege} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const owlItem = [
  {
    itemImg: blog1,
    itemDate: "February 6, 2023",
    itemTitle: "Top 10 Most Populars Google Chrome Extensions",
    itemDesc: "Monotonectally actualize customer methodologie rather than",
    itemAuthor: "Alex Collins",
    itemMore: "Learn More",
  },
  {
    itemImg: blog2,
    itemDate: "February 6, 2023",
    itemTitle: "Top 10 Most Populars Google Chrome Extensions",
    itemDesc: "Monotonectally actualize customer methodologie rather than",
    itemAuthor: "Alex Collins",
    itemMore: "Learn More",
  },
  {
    itemImg: blog3,
    itemDate: "February 6, 2023",
    itemTitle: "Top 10 Most Populars Google Chrome Extensions",
    itemDesc: "Monotonectally actualize customer methodologie rather than",
    itemAuthor: "Alex Collins",
    itemMore: "Learn More",
  },
  {
    itemImg: blog4,
    itemDate: "February 6, 2023",
    itemTitle: "Top 10 Most Populars Google Chrome Extensions",
    itemDesc: "Monotonectally actualize customer methodologie rather than",
    itemAuthor: "Alex Collins",
    itemMore: "Learn More",
  },
  {
    itemImg: blog1,
    itemDate: "February 6, 2023",
    itemTitle: "Top 10 Most Populars Google Chrome Extensions",
    itemDesc: "Monotonectally actualize customer methodologie rather than",
    itemAuthor: "Alex Collins",
    itemMore: "Learn More",
  },
  {
    itemImg: blog2,
    itemDate: "February 6, 2023",
    itemTitle: "Top 10 Most Populars Google Chrome Extensions",
    itemDesc: "Monotonectally actualize customer methodologie rather than",
    itemAuthor: "Alex Collins",
    itemMore: "Learn More",
  },
  {
    itemImg: blog3,
    itemDate: "February 6, 2023",
    itemTitle: "Top 10 Most Populars Google Chrome Extensions",
    itemDesc: "Monotonectally actualize customer methodologie rather than",
    itemAuthor: "Alex Collins",
    itemMore: "Learn More",
  },
  {
    itemImg: blog4,
    itemDate: "February 6, 2023",
    itemTitle: "Top 10 Most Populars Google Chrome Extensions",
    itemDesc: "Monotonectally actualize customer methodologie rather than",
    itemAuthor: "Alex Collins",
    itemMore: "Learn More",
  },
];
export default Home_Blog;
