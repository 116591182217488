import React from "react";
import PageTitle from "../../commoncomponents/commonPageTitle/pageTitle";
import "../../scss/career/_careerbenefits.scss";

const CareerBenefits = () => {
  return (
    <>
      <section className="careerbenefit_section">
        <div className="container my-5">
          <PageTitle
            h6_title="WHY CHOOSE US"
            h1_title="What Are The Benefits?"
          />
          <div className="row careerbenifit_card p-4 ">
            <div className="col-lg-4 col-md-6 col-sm-12 my-3">
              <div className="single_card">
                <div className="row">
                  <div className="col-lg-2">
                    <div className="single_card_icon">
                      <i className="fa fa-users"></i>
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div className="single_card_content">
                      <h6 className="mb-1">Great Work Environment</h6>
                      <p>
                        Uisque congue sodales suscipit for. Praesent varius
                        velit sed sementic, nec placerat eros blandit.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 my-3">
              <div className="single_card">
                <div className="row">
                  <div className="col-lg-2">
                    <div className="single_card_icon">
                      <i className="fa fa-truck"></i>
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div className="single_card_content">
                      <h6 className="mb-1">Unlimited PTO</h6>
                      <p>
                        Uisque congue sodales suscipit for. Praesent varius
                        velit sed sementic, nec placerat eros blandit.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 my-3">
              <div className="single_card">
                <div className="row">
                  <div className="col-lg-2">
                    <div className="single_card_icon">
                      <i className="fa-regular fa-clock"></i>
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div className="single_card_content">
                      <h6 className="mb-1">Flexible Hours</h6>
                      <p>
                        Uisque congue sodales suscipit for. Praesent varius
                        velit sed sementic, nec placerat eros blandit.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 my-3">
              <div className="single_card">
                <div className="row">
                  <div className="col-lg-2">
                    <div className="single_card_icon">
                      <i className="fa fa-plus-square"></i>
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div className="single_card_content">
                      <h6 className="mb-1">Medical Insurance</h6>
                      <p>
                        Uisque congue sodales suscipit for. Praesent varius
                        velit sed sementic, nec placerat eros blandit.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 my-3">
              <div className="single_card">
                <div className="row">
                  <div className="col-lg-2">
                    <div className="single_card_icon">
                      <i className="fa fa-line-chart"></i>
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div className="single_card_content">
                      <h6 className="mb-1">Career Growth</h6>
                      <p>
                        Uisque congue sodales suscipit for. Praesent varius
                        velit sed sementic, nec placerat eros blandit.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 my-3">
              <div className="single_card">
                <div className="row">
                  <div className="col-lg-2">
                    <div className="single_card_icon">
                      <i className="fa-regular fa-lightbulb"></i>
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div className="single_card_content">
                      <h6 className="mb-1">Great Culture</h6>
                      <p>
                        Uisque congue sodales suscipit for. Praesent varius
                        velit sed sementic, nec placerat eros blandit.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CareerBenefits;
