import React from "react";
import Banner from "../../../commoncomponents/commonhead/BannerHead";
import PageTitle from "../../../commoncomponents/commonPageTitle/pageTitle";
import shape2 from "../../../assets/services/mobappdevelopment/shape2.png";
import About_Testimonial from "../../about/About_Testimonial";
import CommonResource from "../../../commoncomponents/commonResource/CommonResource";

const FrontEndDevelopment = () => {
  return (
    <>
      <Banner title="Business Solution" />
      <section className="frontend_section pt-85 pb-10">
        <div className="front_info_tech pt-40 pb-70">
          <div className="container">
            <PageTitle
              h6_title="BUSINESS SOLUTION"
              h1_title="Solutions For"
              span_text="Information Technology"
            />
            <div className="row">
              <div className="col-lg-5 col-md-5">
                <h2>
                  Techno specializes in technological and IT-related services
                  such as product engineering, warranty management, building
                  cloud, infrastructure, network, etc.
                </h2>
              </div>
              <div className="col-lg-7 col-md-7">
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have as suffered alteration in
                  some form, by injected humour, or randomised words which don't
                  look even slightly believable. If you are going to use a
                  passage of Lorem Ipsum, you need to be sure there isn't
                  anything embarrassing hidden in the middle of text. All the
                  Lorem Ipsum generators awesome idea for your business
                </p>
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <a href="#" className="button_1">
                      Get full free any quote here
                    </a>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <a href="#" className="button_2">
                      Make a consult with live chat
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="service_area bg_color2 pt-40 pb-70">
          <div className="container">
            <PageTitle
              h6_title="Solutions"
              h1_title="Reliable IT Services"
              span_text="For Businesses"
            />
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="service_style_four pt-30 pl-4 pr-3 mb-4 pb-20">
                  <div className="service_style_four_icon pb-3">
                    <i className="fa fa-users"></i>
                  </div>
                  <div className="service_style_four_title pb-2">
                    <h4>Techno Management Goals</h4>
                  </div>
                  <div className="service_style_four_text">
                    <p>
                      Morem ipsum dolor sit ameter conse an anglse sicing sombar
                      incidence techno there is a awesome company
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="service_style_four pt-30 pl-4 pr-3 mb-4 pb-20">
                  <div className="service_style_four_icon pb-3">
                    <i className="fa fa-sitemap"></i>
                  </div>
                  <div className="service_style_four_title pb-2">
                    <h4>Infrastructure Integration</h4>
                  </div>
                  <div className="service_style_four_text">
                    <p>
                      Morem ipsum dolor sit ameter conse an anglse sicing sombar
                      incidence techno there is a awesome company
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="service_style_four pt-30 pl-4 pr-3 mb-4 pb-20">
                  <div className="service_style_four_icon pb-3">
                    <i className="fa fa-pie-chart"></i>
                  </div>
                  <div className="service_style_four_title pb-2">
                    <h4>Quality Control System</h4>
                  </div>
                  <div className="service_style_four_text">
                    <p>
                      Morem ipsum dolor sit ameter conse an anglse sicing sombar
                      incidence techno there is a awesome company
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="service_style_four pt-30 pl-4 pr-3 mb-4 pb-20">
                  <div className="service_style_four_icon pb-3">
                    <i className="fa-regular fa-user"></i>
                  </div>
                  <div className="service_style_four_title pb-2">
                    <h4>Highly Professional Staffs</h4>
                  </div>
                  <div className="service_style_four_text">
                    <p>
                      Morem ipsum dolor sit ameter conse an anglse sicing sombar
                      incidence techno there is a awesome company
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="service_style_four pt-30 pl-4 pr-3 mb-4 pb-20">
                  <div className="service_style_four_icon pb-3">
                    <i className="fa fa-database"></i>
                  </div>
                  <div className="service_style_four_title pb-2">
                    <h4>Efficient Database Security</h4>
                  </div>
                  <div className="service_style_four_text">
                    <p>
                      Morem ipsum dolor sit ameter conse an anglse sicing sombar
                      incidence techno there is a awesome company
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="service_style_four pt-30 pl-4 pr-3 mb-4 pb-20">
                  <div className="service_style_four_icon pb-3">
                    <i className="fa fa-cogs"></i>
                  </div>
                  <div className="service_style_four_title pb-2">
                    <h4>Reliable Multi-Function</h4>
                  </div>
                  <div className="service_style_four_text">
                    <p>
                      Morem ipsum dolor sit ameter conse an anglse sicing sombar
                      incidence techno there is a awesome company
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-40 pb-70">
          <div className="container pt-85">
            <PageTitle
              h6_title="INDUSTRIES"
              h1_title="Preparing For Your Success, We Provide Truly"
              span_text="Prominent IT Solutions."
            />
            <div className="row">
              {front_service.map((service, i) => {
                return (
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="service_style_seven pt-3 pb-3 pl-4 pr-3 mb-30">
                      <div className="service_style_seven_icon mr-3">
                        {service.icon}
                      </div>
                      <div className="service_style_seven_content ">
                        <div className="service_style_seven_title pb-3">
                          <h4>{service.title}</h4>
                        </div>
                        <div className="service_style_seven_text">
                          <p>{service.desc}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <CommonResource />
        <About_Testimonial />
      </section>
    </>
  );
};
const front_service = [
  {
    icon: <i className="fa fa-desktop"></i>,
    title: "IT Design",
    desc: " There are many variations of passages of Lorem Ipsum an available, but the majority have suffered alteration in thes some form, by injected humour, or randomised",
  },
  {
    icon: <i className="fa-regular fa-share-from-square"></i>,
    title: "IT Management",
    desc: " There are many variations of passages of Lorem Ipsum an available, but the majority have suffered alteration in thes some form, by injected humour, or randomised",
  },
  {
    icon: <i className="fa-regular fa-clock"></i>,
    title: "Data Security",
    desc: " There are many variations of passages of Lorem Ipsum an available, but the majority have suffered alteration in thes some form, by injected humour, or randomised",
  },
  {
    icon: <i className="fa-solid fa-circle-notch"></i>,
    title: "Business Reform",
    desc: " There are many variations of passages of Lorem Ipsum an available, but the majority have suffered alteration in thes some form, by injected humour, or randomised",
  },
  {
    icon: <i className="fa fa-transgender-alt"></i>,
    title: "Infrastructure Plan",
    desc: " There are many variations of passages of Lorem Ipsum an available, but the majority have suffered alteration in thes some form, by injected humour, or randomised",
  },
  {
    icon: <i className="fa fa-recycle"></i>,
    title: "Firewall Advance",
    desc: " There are many variations of passages of Lorem Ipsum an available, but the majority have suffered alteration in thes some form, by injected humour, or randomised",
  },
];
export default FrontEndDevelopment;
