import React, { useEffect } from "react";
import Banner from "../commoncomponents/commonhead/BannerHead";
import BusinessImages from "../components/portfolio/BusinessImages";
import BusinessWork from "../components/portfolio/BusinessWork";
import "../scss/_portfolio.scss";

const Portfolio = () => {
  useEffect(() => {
    window.scrollTo({ top: "0", behavior: "smooth" });
  }, []);
  return (
    <>
      <Banner title={"Portfolio"} />
      <BusinessWork />
      <BusinessImages />
    </>
  );
};

export default Portfolio;
