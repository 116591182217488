import React from "react";
import HomewhyChoose from "../../assets/home/home_whyChoose/choose.png";
const Home_whyChoose = () => {
  return (
    <>
      <section className="whyChoose_section pb-100">
        <div className="container pb-120">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="whyChoose_section_title mb-30 mt-3 text-left">
                <div className="whyChoose_section_sub_title mb-3">
                  <h5>Why Choose Us</h5>
                </div>
                <div className="whyChoose_section_main_title">
                  <h1>
                    Branding New Business Solutions, Ideas & The Digital{" "}
                    <span>Advanced.</span>
                  </h1>
                </div>
                <div className="whyChoose_section_content_text">
                  <p>
                    Completely strategize client-centric niche markets for go
                    forward Phosfluorescently iterate efficient internal or
                    organic.
                  </p>
                </div>
              </div>
              <div className="progressbar_section">
                <span className="progressbar_1">HTML</span>
                <div id="bar1" className="barfiller">
                  <div className="tipWrap">
                    <span className="tip">65%</span>
                  </div>
                  <span className="fill" data-percentage="65"></span>
                </div>
                <span className="progressbar_1">CSS</span>
                <div id="bar1" className="barfiller">
                  <div className="tipWrap">
                    <span className="tip_2">90%</span>
                  </div>
                  <span className="fill_2" data-percentage="90"></span>
                </div>
                <span className="progressbar_1">JAVA</span>
                <div id="bar1" className="barfiller">
                  <div className="tipWrap">
                    <span className="tip_3">80%</span>
                  </div>
                  <span className="fill_3" data-percentage="80"></span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="whyChoose_thumb">
                <img className="lang_choose_img" src={HomewhyChoose} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="business_project pt-60 pb-80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center mt-5">
                <div className="call-do-action-content">
                  <div className="call-do-action-title text-center">
                    <h1>Hey! Do You Need any Kinds</h1>
                    <h1>of Business Projects?</h1>
                    <div className="call-button text-center">
                      <button>
                        Get Start Now <span className="arrow">&#x2192;</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home_whyChoose;
