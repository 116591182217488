import React from "react";
import FrontEndDevelopment from "../components/service/frontenddevelopment/FrontEndDevelopment";

const Front_End_Dev = () => {
  return (
    <>
      <FrontEndDevelopment />
    </>
  );
};

export default Front_End_Dev;
