import React, { useEffect, useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import Contact from "./pages/Contact";
import Career from "./pages/Career";
import WebService from "./pages/Web_app_dev";
import MobileAppDev from "./pages/Mob_app_dev";
import Portfolio from "./pages/Portfolio";
import About from "./pages/About";
import Blog from "./pages/Blog";
import BlogDetail from "./pages/BlogDetail";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./pages/Home";
import FrontEndDevelopment from "./pages/Front_End_Dev";
import BackendDevelopment from "./components/service/backenddevelopment/BackendDevelopment";
import Team from "./pages/Team";
import "./App.scss";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CareerDetail from "./pages/CareerDetail";
import Aos from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";

function App() {
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  useEffect(() => {
    Aos.init();
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Header />
      <Helmet>
        <title>Shine Infosoft</title>
        <meta name="description" content="Find Best It Service Solution" />
        <meta
          property="og:title"
          content="Pirotan Technolabs Pvt. Ltd. | Software Developement Company"
        />
        <meta
          property="og:description"
          content="Pirotan Technolabs is Specialize in AngularJs & ReactJs web application, .Net, & server side for NodeJs"
        />
        <meta property="og:image" content="url_to_image" />
        <meta
          name="twitter:title"
          content="Pirotan Technolabs Pvt. Ltd. | Software Developement Company"
        />
        <meta
          name="twitter:description"
          content="Pirotan Technolabs is Specialize in AngularJs & ReactJs web application, .Net, & server side for NodeJs"
        />
        <meta name="twitter:image" content="url_to_image" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/team_member" element={<Team />} />
        <Route path="/web_service" element={<WebService />} />
        <Route path="/mob_service" element={<MobileAppDev />} />
        <Route path="/front_service" element={<FrontEndDevelopment />} />
        <Route path="/back_service" element={<BackendDevelopment />} />
        <Route path="/career" element={<Career />} />
        <Route path="/career_detail" element={<CareerDetail />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blogdetail" element={<BlogDetail />} />
      </Routes>
      <Footer />
      {showTopBtn && (
        <a className="scrollUp icon-style" onClick={goToTop}>
          <i className="fa fa-angle-up"></i>
        </a>
      )}{" "}
    </>
  );
}

export default App;
