import React from 'react'
import Banner from '../commoncomponents/commonhead/BannerHead';
import BlogDescription from '../components/blogdetail/BlogDescription';
import "../scss/_blogdetail.scss";

const BlogDetail = () => {
  return (
    <>
        <Banner title={"Blog Details"} />
        <BlogDescription />
    </>
  )
}

export default BlogDetail