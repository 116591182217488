import React from 'react'
import MobileAppDev from '../components/service/mobileappdevlopement/mobileappdevlopment'

const Mob_app_dev = () => {
  return (
    <>
      <MobileAppDev/>
    </>
  )
}

export default Mob_app_dev
