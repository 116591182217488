import React from 'react'
import Banner from '../commoncomponents/commonhead/BannerHead';
import BlogGrids from '../components/blog/BlogGrids';
import "../scss/_blog.scss";

const Blog = () => {
    return (
        <>
            <Banner title={"Blog Grid"} />
            <BlogGrids />
        </>
    )
}

export default Blog