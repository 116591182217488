import React from "react";
import shape2 from "../../assets/services/mobappdevelopment/shape2.png";

const CommonResource = () => {
  return (
    <>
      <div className="call_do_action bg_color2 pt-20">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-6">
              <div className="section_title text_left mb-40 mt-3">
                <div className="section_sub_title uppercase mb-3">
                  <h6>RESOURCE</h6>
                </div>
                <div className="section_main_title">
                  <h1>Get A Copy Of Brochure On</h1>
                  <h1>
                    <span>Brand New IT Tech.</span>
                  </h1>
                </div>
              </div>
              <div className="call_do_action_button">
                <div className="button two">
                  <a href="#">Download More</a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="acction_thumb">
                <img src={shape2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonResource;
