import React from "react";
import Banner from "../commoncomponents/commonhead/BannerHead";
import CareerBenefits from "../components/carrier/CareerBenefits";
import CareerHiring from "../components/carrier/CareerHiring";

const Career = () => {
  return (
    <>
      <Banner title="Career" />
      <CareerBenefits />
      <CareerHiring />
    </>
  );
};

export default Career;
