import React from "react";
import "../../scss/contact/_contactdetail.scss";

const ContactDetail = () => {
  return (
    <section className="contact_detail_sec">
      <div className="container py-3">
        <div className="row text-center py-5">
          <div className="col-lg-12 ">
            <div className="heading_p py-2">
              <p>CONTACT US</p>
            </div>
            <div className="heading_h1 py-2">
              <h1>We Are Here For You</h1>
            </div>
          </div>
        </div>
        <div className="row pb-5 contact_detail_row">
          <div className="col-lg-4 col-md-6 col-sm-12 contact_card">
            <div className="card p-4">
              <div className="inner_card">
                <div className="single_contact_icon">
                  <i className="fa fa-phone"></i>
                </div>
                <div className="songle_contact_content">
                  <h4>Phone Number</h4>
                  <p className="m-0 pt-2">+880 1314 183818</p>
                  <p>+880 19051 45672</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 contact_card">
            <div className="card p-4">
              <div className="inner_card">
                <div className="single_contact_icon">
                  <i className="fa fa-phone"></i>
                </div>
                <div className="songle_contact_content">
                  <h4>Email Address</h4>
                  <p className="m-0 pt-2">info@example.com</p>
                  <p>gmail@example.com</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 contact_card">
            <div className="card p-4">
              <div className="inner_card">
                <div className="single_contact_icon">
                  <i className="fa fa-phone"></i>
                </div>
                <div className="songle_contact_content">
                  <h4>Office Address</h4>
                  <p className="m-0 pt-2">56/A-102-Middle Point, USA</p>
                  <p>New York, US</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactDetail;
