import React from "react";
import PageTitle from "../../commoncomponents/commonPageTitle/pageTitle";

const BusinessWork = () => {
  return (
    <>
      <section className="portfolio_businesswork_section pt-80 pb-70">
        <div className="container">
          <PageTitle
            h6_title="FEATURES PORTFOLIO"
            h1_title="Our Some Work"
            h1_second_title="For Your Creative Business"
          />
          <div className="row">
            <div className="col-md-12">
              <div className="businesswork_nav">
                <div className="businesswork_menu">
                  <ul className="businesswork_menu-filtering">
                    <li className="current_menu_item" data-filter="*">
                      All Works
                    </li>
                    <li data-filter=".physics">Branding</li>
                    <li data-filter=".cemes">Prototype</li>
                    <li data-filter=".math">UX Research</li>
                    <li data-filter=".english">Web Design</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BusinessWork;
