import React from "react";
import CountUp from "react-countup";
import home_about_2 from "../../../assets/about/home_about_2.jpg";
import Banner from "../../../commoncomponents/commonhead/BannerHead";
import CommonResource from "../../../commoncomponents/commonResource/CommonResource";
import About_Testimonial from "../../about/About_Testimonial";

const BackendDevelopment = () => {
  return (
    <>
      <Banner title="Managed IT Services" />
      <section className="solution_section pt-85">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="home_about_2">
                <img src={home_about_2} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="text_left">
                <div className="uppercase">
                  <h6>30 YEARS OF EXPERIENCE</h6>
                </div>
                <h1>
                  Preparing For Your Success Provide Best{" "}
                  <span className="blue">IT Solutions.</span>
                </h1>
                <div className="decorative_bar">
                  <div className="decorative_bar_bg"></div>
                </div>
              </div>
              <div className="solution_p pt-5">
                <p>
                  Voice and Data Systems are crucial to the success or failure
                  of most businesses. any companies provide laptops, cell
                  phones.
                </p>
              </div>
              <div className="solution_desc">
                <div className="solution_desc_border">
                  <p>
                    The standard chunk of Lorem Ipsum used since the 1500s is
                    and reproduced below for those interested. Sections 1.10.32
                    and also 1.10.33 from “de Finibus Bonorum et Malorum" by
                    Cicero are alse reproduced in their exact original form,
                    accompanied
                  </p>
                  <p>
                    The standard chunk of Lorem Ipsum used since the 1500s is
                    and reproduced below for those interested
                  </p>
                </div>
              </div>
              <div className="solution_desc_botton">
                <div className="solution_desc_botton_2">
                  <a href="#">More Details</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="counter_section" id="counter_section">
          <div className="container">
            <div className="row counter_bg_up counter_down_to_up pt-50 pb-45">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="single_counter text-center mb-4 b-right">
                  <div className="counter_text">
                    <h1>
                      <CountUp duration={4} end={15} />K
                    </h1>
                  </div>
                  <div className="counter_desc">
                    <h5>Happy Clients</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="single_counter text-center mb-4 b-right">
                  <div className="counter_text">
                    <h1>
                      <CountUp duration={4} end={1280} />+
                    </h1>
                  </div>
                  <div className="counter_desc">
                    <h5>Account Number</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="single_counter text-center mb-4 b-right">
                  <div className="counter_text">
                    <h1>
                      <CountUp duration={4} end={10} />
                      <span>K</span>
                    </h1>
                  </div>
                  <div className="counter_desc">
                    <h5>Finished Projects</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="single_counter text-center mb-4">
                  <div className="counter_text">
                    <h1>
                      <CountUp duration={4} end={992} />
                      <span>+</span>
                    </h1>
                  </div>
                  <div className="counter_desc">
                    <h5>Win Awards</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CommonResource />
        <About_Testimonial />
      </section>
    </>
  );
};

export default BackendDevelopment;
