import React from "react";
import { Link } from "react-router-dom";
import blog1 from "../../assets/blog/blog1.jpg";
import blog2 from "../../assets/blog/blog2.jpg";
import blog3 from "../../assets/blog/blog3.jpg";
import blog4 from "../../assets/blog/blog4.jpg";

const BlogGrids = () => {
  const bloggriddata = [
    {
      img: blog1,
      date: "January 3, 2023",
      content: "The five device you need to work anytime",
    },
    {
      img: blog2,
      date: "December 3, 2023",
      content: "The five device you need to work anytime",
    },
    {
      img: blog3,
      date: "August 5, 2023",
      content: "The five device you need to work anytime",
    },
    {
      img: blog4,
      date: "August 5, 2023",
      content: "The five device you need to work anytime",
    },
    {
      img: blog1,
      date: "August 5, 2023",
      content: "The five device you need to work anytime",
    },
    {
      img: blog2,
      date: "August 5, 2023",
      content: "The five device you need to work anytime",
    },
  ];

  return (
    <>
      <section className="bloggrid_main_section">
        <div className="container">
          <div className="row my-5">
            {bloggriddata &&
              bloggriddata.map((el) => {
                return (
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="grid_single_blog mb-30">
                      <div className="grid_single_blog_thumb pb-4">
                        <Link to="">
                          <img src={el.img} alt="#" />
                        </Link>
                      </div>
                      <div className="grid_single_blog_content pl-4 pr-4">
                        <div className="grid_blog_meta">
                          <Link to="#">"Techno "</Link>
                          <span className="grid_meta_date pl-3">{el.date}</span>
                        </div>
                        <div className="grid_blog_page_title pb-35">
                          <h3>
                            <a to="#">{el.content}</a>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogGrids;
